import axios from '~/util/axios'
import { query } from './graphqlService.js'

export default {
  getServicerProfile (code) {
    if (code !== undefined) {
      code = '?code=' + code
    } else {
      code = ''
    }
    return axios.get('/api/v1/servicer_profiles' + code)
  },
  getServicerProfileById (id) {
    const params = `
      query servicer($servicer_id: ID!) {
        servicer_profile (servicer_id: $servicer_id) {
          id
          name
          unformatted_phone
          email
          website
          license
          created_at
          updated_at
          profile_file_name
          profile_content_type
          profile_file_size
          profile_updated_at
          header_file_name
          header_content_type
          header_file_size
          header_updated_at
          street1
          street2
          city
          state
          zip
          last_name
          legal_disclaimer
          has_scanner
          rss_feed_url
          has_calculator
          company
          has_cobrand
          office_unformatted_phone
          has_myloan
          calc_btn_txt
          calc_btn_action
          calc_btn_type
          cost_center
          allow_loan_auto_connect
          title
          app_link_page_text
          app_icon
          affordability_disclaimer
          copyright
          education_disclaimer
          website_disclaimer
          privacy_policy_link
          consumer_access_link
          calc_default_county
          calc_default_state_abb
          has_1003
          has_prequal
          has_prequal_on_loans
          has_prequal_on_loan_apps
          loan_app_submitted_notifications
          shares_count
          allow_unassociated_prequal
          is_kiosk
          has_education
          display_share_button
          has_cmps
          has_vanick
          has_pre_approval
          has_pre_approval_on_loans
          has_pre_approval_on_loan_apps
          allow_credit_order_on_applications
          cmps_id
          allow_appraisals
          has_credit_reports
          los_user_id
          has_cimmaron
          allow_loan_app_access
          allow_loan_app_access_after_import
          allow_loan_app_edit
          allow_loan_app_return
          allow_loan_app_create
          has_ob_pricing
          has_contextual_chat
          guid
          effective_allow_continue_skip_when_choosing_lo
        }
      }
    `
    const variables = {
      servicer_id: id.toString()
    }
    return query(params, variables)
  }
}
