<template>
  <div>
    <v-dialog
      v-model="store.editAccountDialog"
      :fullscreen="$vuetify.breakpoint.xs"
      :transition="$vuetify.breakpoint.xs ? 'dialog-bottom-transition' : ''"
      :overlay="false"
      persistent
      width="400px"
    >
      <v-card :class="{'dialog--ontop': $vuetify.breakpoint.smAndAbove}">
        <v-toolbar
          v-if="$vuetify.breakpoint.xs"
          dark
          class="primary"
          style="height:60px;"
        >
          <v-btn
            aria-label="close"
            icon
            dark
            @click.native="store.editAccountDialog = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>Edit Account</v-toolbar-title>
        </v-toolbar>
        <v-card-title v-if="$vuetify.breakpoint.smAndAbove">
          Edit account
        </v-card-title>
        <v-card-text v-if="store.currentUser !== null">
          <v-form v-model="formIsValid">
            <v-text-field
              v-model="store.currentUser.name"
              label="First name *"
              :rules="[fieldIsRequired]"
              required
            />
            <v-text-field
              v-model="store.currentUser.last_name"
              label="Last name *"
              :rules="[fieldIsRequired]"
              required
            />
            <v-text-field
              v-model="store.currentUser.unformatted_phone"
              :rules="[fieldIsRequired]"
              :error-messages="errors['phone']"
              label="Phone *"
              required
              @focus="removeValidationErrors( 'phone' )"
              @blur="validatePhone( store.currentUser.unformatted_phone )"
              @keyup="formatPhone"
            />
            <v-text-field
              v-model="store.currentUser.password"
              :error-messages="errors[ 'password' ]"
              label="Set a new password"
              type="password"
              @blur="validatePassword"
              @focus="removeValidationErrors( 'password' )"
            />
            <v-text-field
              v-model="store.currentUser.password_confirmation"
              :error-messages="errors[ 'password_confirmation' ]"
              label="Confirm new password"
              type="password"
              @blur="validatePasswordConfirmation"
              @focus="removeValidationErrors( 'password_confirmation' )"
            />
            <v-text-field
              v-model="store.currentUser.email"
              :disabled="true"
              label="Email"
              hint="If you would like to change your email address, please reach out to your loan officer."
              persistent-hint
            />
            <br>
            <small>*indicates required field</small>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            aria-label="save"
            class="primary white--text"
            :disabled="!formIsValid"
            @click.native="saveUser"
          >
            Save
          </v-btn>
          <v-btn
            aria-label="save"
            class="grey--text"
            outline
            @click.native="store.editAccountDialog=false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import snUserService from '../../services/snUserService.js'
import snValidationsService from '../../services/snValidationsService.js'

export default {
  name: 'BorrowerEditAccountDialog',
  filters: {},
  data () {
    return {
      errors: { password: [], password_confirmation: [], phone: [] },
      errorsFromServer: [],
      store: this.$store,
      formIsValid: false,
      fieldIsRequired: v => !!v || 'This field is required'
    }
  },
  methods: {
    formDataIsValid () {
      if (this.store.currentUser.name.length > 0 && this.store.currentUser.last_name.length > 0 && this.errors.password.length === 0 && this.errors.password_confirmation.length === 0 && this.errors.phone.length === 0) {
        return true
      } else {
        return false
      }
    },
    formatPhone () {
      if (this.store.currentUser.unformatted_phone !== undefined && this.store.currentUser.unformatted_phone !== null) {
        var temp = this.store.currentUser.unformatted_phone.replace(/\D/g, '')
        if (temp.length > 6) {
          temp = `(${temp.slice(0, 3)}) ${temp.slice(3, 6)}-${temp.slice(6)}`
        } else if (temp.length > 3) {
          temp = `(${temp.slice(0, 3)}) ${temp.slice(3)}`
        }
        this.store.currentUser.unformatted_phone = temp
      }
    },
    removeValidationErrors (key) {
      this.$set(this.errors, key, [])
    },
    saveUser () {
      if (this.formDataIsValid()) {
        var that = this
        snUserService.update(that.store.currentUser)
          .then(response => {
            if (response.data.msg !== 'ok') {
              that.errorsFromServer.push(response.data)
              if (response.data.msg.password) {
                that.$set(that.errors, 'password', response.data.msg.password)
              }
              console.log(response.data)
            } else {
              that.store.currentUser.password = null
              that.store.currentUser.password_confirmation = null
              that.dialog = null
              this.$snackbard.show({ text: 'Account updated.' })
              if (that.$vuetify.breakpoint.xs) {
                that.store.editAccountDialog = false
              }
            }
          })
          .catch(e => {
            this.$sentry.captureException(e)
            console.log(e)
          })
      }
    },
    validatePassword () {
      if (this.store.currentUser.password === null) {
        this.$set(this.errors, 'password', [])
      } else if (this.store.currentUser.password === '') {
        this.$set(this.errors, 'password', [])
      } else if (this.store.currentUser.password !== null && this.store.currentUser.password !== undefined && (this.store.currentUser.password.length < 9 || !/(?=.*[a-z])/.test(this.store.currentUser.password) || !/(?=.*[A-Z])/.test(this.store.currentUser.password) || !/(?=.*[0-9])/.test(this.store.currentUser.password))) {
        var requirements = []
        if (this.store.currentUser.password !== null && this.store.currentUser.password.length < 9) { requirements.push('9 characters') }
        if (!/(?=.*[a-z])/.test(this.store.currentUser.password)) { requirements.push('1 lowercase character') }
        if (!/(?=.*[A-Z])/.test(this.store.currentUser.password)) { requirements.push('1 uppercase character') }
        if (!/(?=.*[0-9])/.test(this.store.currentUser.password)) { requirements.push('1 number') }
        this.$set(this.errors, 'password', 'must have at least: ' + requirements.join(', '))
      } else {
        this.$set(this.errors, 'password', [])
      }
    },
    validatePasswordConfirmation () {
      var pw = this.store.currentUser.password
      var pwc = this.store.currentUser.password_confirmation
      if (pw) {
        if (pw === pwc) {
          this.$set(this.errors, 'password_confirmation', [])
        } else if (pw !== pwc) {
          this.$set(this.errors, 'password_confirmation', 'Password confirmation field must match password field.')
        } else if (pwc === null || pwc === undefined || pwc === '') {
          this.$set(this.errors, 'password_confirmation', 'This field is required.')
        }
      } else if (!pw && pwc) {
        this.$set(this.errors, 'password_confirmation', 'Password confirmation field must match password field.')
      } else {
        this.$set(this.errors, 'password_confirmation', [])
      }
    },
    validatePhone: function (phone) {
      if (!phone) {
        this.$set(this.errors, 'phone', ['This is a required field.'])
      } else if (snValidationsService.phone(phone)) {
        this.$set(this.errors, 'phone', [])
      } else {
        this.$set(this.errors, 'phone', ['Invalid phone number.'])
      }
    }

  }
}
</script>

<style scoped>
  .dialog--ontop {
    position: fixed;
    top: 20px;
    width: 400px;
  }
</style>
