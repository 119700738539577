<template>
  <v-app
    v-show="sn_themeMixin_hasColors"
    id="app"
    class="white"
  >
    <BrowserDeprecationWarning />
    <v-navigation-drawer
      v-if="$vuetify.breakpoint.mdAndDown || !$route.path.includes('login')"
      v-model="store.sidenav"
      width="264"
      left
      app
    >
      <v-list
        class="barlow-font"
        style="padding-left: 15px; padding-right: 15px;"
      >
        <v-list-tile style="padding-top: 17px; padding-bottom: 17px;">
          <img
            v-if="computedLogoImageUrl"
            style="max-width: 100%;"
            :src="computedLogoImageUrl"
            alt="Company Logo"
          >
        </v-list-tile>
        <template v-if="(showDesktopSidenav || $vuetify.breakpoint.mdAndDown) && viewMyLoanTab">
          <SidenavTile
            icon="arrow-left-circle"
            title="Switch Loan"
            :click-action="switchLoans"
            :is-active-function="() => { return false }"
          />
          <SidenavTile :divider="true" />
        </template>
        <template v-for="item in sidenavOptions">
          <SidenavTile
            v-if="!Object.keys(item).includes('shouldShow') || item.shouldShow()"
            :key="sideNavItemTitle(item)"
            :divider="item.divider"
            :icon="item.icon"
            :title="sideNavItemTitle(item)"
            :click-action="item.clickAction"
            :is-active-function="item.isActive"
          />
        </template>
        <SidenavTile
          style="position: absolute; bottom: 20px; width:234px;"
          icon="question-mark"
          :title="splashHeaderNavSupport"
          :click-action="() => { if ($vuetify.breakpoint.mdAndDown) store.sidenav = false;store.supportDialog = !store.supportDialog }"
          :is-active-function="() => { return false }"
        />
      </v-list>
    </v-navigation-drawer>
    <v-content>
      <div v-if="!store.inMobileApp && !$route.path.includes('mobile_app_redirect')">
        <div
          v-if="store.proxyLogIn === true"
          id="super-user-banner"
        >
          <a href="/logout">YOU ARE LOGGED IN AS SOMEONE ELSE. CLICK TO GO BACK TO BEING YOURSELF.</a>
        </div>
        <div
          id="header1"
          class="primary"
        />
        <div
          id="header2"
          class="secondary"
        />
        <div
          id="toolbar"
          class="pt-2 white"
        >
          <v-container :class="headerPadding">
            <v-toolbar
              :ripple="false"
              :style="{height: ($vuetify.breakpoint.smAndDown ? '50px' : '64px')}"
              flat
              class="white"
            >
              <v-toolbar-title
                class="ml-0"
              >
                <h1
                  v-if="showDesktopSidenav && $vuetify.breakpoint.lgAndUp"
                  class="display-1 barlow-condensed-font sn-font-weight-semi-bold"
                >
                  {{ pageTitle }}
                </h1>
                <a
                  v-else
                  :href="computedLogoLink"
                  style="text-decoration:none;"
                >
                  <default-company-logo
                    v-if="computedLogoImageUrl"
                    :logo-src="computedLogoImageUrl"
                  />
                </a>
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items v-if="$vuetify.breakpoint.lgAndUp">
                <template v-if="!showDesktopSidenav">
                  <v-btn
                    v-if="viewMyLoanTab && !this.$route.path.includes('borrower/loans')"
                    slot="activator"
                    aria-label="my loans"
                    flat
                    class="hoverable py-1 grey--text text--darken-4"
                    @click="switchLoans()"
                  >
                    {{ splashHeaderMyLoans }}
                  </v-btn>

                  <div
                    v-if="viewLocaleTab"
                    aria-hidden="true"
                    class="v-icon mt-1 sn-icon-earth"
                    style="font-size: 25px;"
                  />
                  <v-btn
                    v-if="viewLocaleTab"
                    aria-label="Switch Language"
                    flat
                    class="hoverable fix-vuetify-toolbar-height py-3 grey--text text--darken-4"
                    @click="changeLanguage(`${store.borrowerWebContent.splashHeaderSwitchLocale}`)"
                  >
                    {{ splashHeaderLanguage }}
                  </v-btn>
                  <v-btn
                    v-if="showApp"
                    aria-label="Get the app"
                    flat
                    class="hoverable fix-vuetify-toolbar-height py-3 grey--text text--darken-4"
                    @click="store.getTheAppDialog = !store.getTheAppDialog"
                  >
                    {{ splashHeaderNavGetTheApp }}
                  </v-btn>
                  <v-btn
                    v-if="supportAvailable"
                    aria-label="Support"
                    flat
                    class="hoverable fix-vuetify-toolbar-height py-3 grey--text text--darken-4"
                    @click="store.supportDialog = !store.supportDialog"
                  >
                    {{ splashHeaderNavSupport }}
                  </v-btn>
                </template>
                <div v-if="!$route.path.includes('/borrower/go_mobile/') && $route.query.test !== 'true'">
                  <v-btn
                    v-if="!store.currentUser"
                    flat
                    aria-label="Login"
                    data-cy="borrower-signup-login-btn"
                    class="hoverable fix-vuetify-toolbar-height py-3 grey--text text--darken-4"
                    @click.native="signIn"
                  >
                    {{ splashHeaderNavLogin }}
                  </v-btn>
                  <v-menu
                    v-else
                    id="marriot"
                    bottom
                    left
                    origin="top right"
                  >
                    <v-btn
                      slot="activator"
                      flat
                      data-cy="borrower-signup-my-account-btn"
                      aria-label="My account"
                      class="fix-vuetify-toolbar-height py-3"
                    >
                      {{ splashHeaderNavMyAccount }}
                    </v-btn>
                    <v-list>
                      <v-list-tile
                        v-if="has1003Enhanced && !hideNewApplicationButton"
                        @click.stop="shouldConfirmNewLoanApp ? confirmNewLoanApp = true : createNewLoanApp()"
                      >
                        <v-list-tile-title>{{ computedMenuNewLoanAppText }}</v-list-tile-title>
                      </v-list-tile>
                      <v-list-tile @click.stop="store.editAccountDialog=!store.editAccountDialog;">
                        <v-list-tile-title>{{ splashHeaderNavEditAccount }}</v-list-tile-title>
                      </v-list-tile>
                      <v-list-tile @click="signOutAndGoToLogin">
                        <v-list-tile-title>{{ splashHeaderNavSignOut }}</v-list-tile-title>
                      </v-list-tile>
                    </v-list>
                  </v-menu>
                </div>
              </v-toolbar-items>
              <v-toolbar-items
                v-else-if="$vuetify.breakpoint.mdAndDown"
                class="mr-0"
              >
                <v-toolbar-side-icon
                  class="left"
                  style="height:36px !important"
                  @click.stop="store.sidenav = !store.sidenav"
                />
              </v-toolbar-items>
            </v-toolbar>
            <v-dialog
              v-if="store.activationCode !== null"
              v-model="store.smallGetTheAppDialog"
              :overlay="false"
              fullscreen
              transition="dialog-bottom-transition"
            >
              <v-card>
                <v-toolbar
                  dark
                  class="primary"
                  style="height:60px;"
                >
                  <v-btn
                    icon
                    dark
                    aria-label="Close"
                    @click.native="store.smallGetTheAppDialog = false"
                  >
                    <v-icon>close</v-icon>
                  </v-btn>
                  <v-toolbar-title>Get the app</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-layout
                    v-if="$vuetify.breakpoint.mdAndDown"
                    row
                    wrap
                    class="mt-5 mb-5"
                  >
                    <v-flex
                      v-if="userAgent === 'Android' || userAgent === 'unknown'"
                      xs12
                      sm6
                      class="text-xs-center"
                    >
                      <img
                        :src="googlePlay"
                        class="ml-0 mt-1 hoverable"
                        style="height:80px;"
                        @click="goToInstallLink()"
                      >
                    </v-flex>
                    <v-flex
                      v-if="userAgent === 'iOS' || userAgent === 'unknown'"
                      xs12
                      sm6
                      class="text-xs-center"
                    >
                      <img
                        :src="appleStore"
                        class="ml-0 mt-1 hoverable"
                        style="height:80px;"
                        @click="goToInstallLink()"
                      >
                    </v-flex>
                  </v-layout>
                </v-card-text>
              </v-card>
            </v-dialog>
            <v-dialog
              v-if="$vuetify.breakpoint.smAndDown"
              v-model="store.smallSupportDialog"
              :overlay="false"
              fullscreen
              transition="dialog-bottom-transition"
            >
              <v-card>
                <v-toolbar
                  dark
                  class="primary"
                  style="height:60px;"
                >
                  <v-btn
                    icon
                    dark
                    aria-label="close"
                    @click.native="store.smallSupportDialog = false"
                  >
                    <v-icon>close</v-icon>
                  </v-btn>
                  <v-toolbar-title>{{ splashDialogHelpAndSupportTitle }}</v-toolbar-title>
                </v-toolbar>
                <v-layout row>
                  <v-flex
                    xs12
                    class="pa-5"
                  >
                    <h2 class="title">
                      {{ splashDialogCallSupport }}
                    </h2>
                    <p class="subheading">
                      Call us at <a :href="'tel:+1-' + store.support_phone">{{ store.support.phone }}</a>.
                    </p>
                    <p class="subheading">
                      {{ splashDialogAvailability }} {{ store.support.hours }}.
                    </p>
                    <h2 class="title">
                      {{ splashDialogEmailSupport }}
                    </h2>
                    <p class="subheading">
                      {{ splashDialogEmailSupportAt }} <a :href="'mailto:' + store.support.email">{{ store.support.email }}.</a>
                    </p>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-dialog>
            <borrower-edit-account-dialog />
          </v-container>
        </div>
      </div>
      <main :class="{'gray--background': $vuetify.breakpoint.mdAndUp, 'white--background pt-3 full-height': $vuetify.breakpoint.smAndDown}">
        <router-view @update-store="updateStore" />
      </main>
    </v-content>
    <session-timeout v-if="store.servicer !== null && !store.inMobileApp" />
    <new-loan-app-dialog
      ref="modal"
      v-model="confirmNewLoanApp"
      @confirm="createNewLoanApp"
    />

    <v-dialog
      v-if="store.activationCode !== null"
      v-model="store.getTheAppDialog"
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <div class="headline">
            {{ textMeTheApp }}
          </div>
        </v-card-title>
        <v-card-text>
          <borrower-share-app-by-sms class="ma-0" />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            class="green--text darken-1"
            aria-label="OK"
            flat="flat"
            @click.native="store.getTheAppDialog = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="store.supportDialog"
      width="400"
      class="mt-0"
    >
      <v-card>
        <v-card-title>
          <div class="headline">
            Help & Support
          </div>
        </v-card-title>
        <v-card-text v-if="store.support">
          <h2 class="title">
            Call Support
          </h2>
          <p class="subheading">
            Call us at <a :href="'tel:+1-' + store.support.phone">{{ store.support.phone }}</a>.
          </p>
          <p class="subheading">
            We are available {{ store.support.hours }}.
          </p>
          <h2 class="title">
            Email Support
          </h2>
          <p class="subheading">
            Email your support request to <a :href="'mailto:' + store.support.email">{{ store.support.email }}.</a>
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            class="green--text darken-1"
            flat="flat"
            aria-label="OK"
            @click.native="store.supportDialog = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import brandThemeMixin from '../common/brandThemeMixin'
import newLoanAppDialog from './newLoanAppDialog'
import BorrowerEditAccountDialog from './borrowerEditAccountDialog.vue'
import BorrowerShareAppBySms from './borrowerShareAppBySms'
import SessionTimeout from '../common/snSessionTimeout'
import DefaultCompanyLogo from './defaultCompanyLogo.vue'
import BrowserDeprecationWarning from '~/components/common/browserDeprecationWarning.vue'
import { store } from '../../store/borrowerStore.js'
import StoreGenerator from './borrowerAppStoreGenerator.js'
import Vue from 'vue'
import gql from 'graphql-tag'
import googlePlay from '~/assets/images/google_play.png'
import appleStore from '~/assets/images/apple_store.png'
import SidenavTile from './sidenavTile.vue'

Vue.prototype.$store = store

const currentUserMetaQuery = gql`
  query {
    user {
      app_user {
        total_active_loans
        unlinked_user_loan_apps_count
        loans_count
        active_non_hidden_loans_count
        non_hidden_loans_count
        servicer_profile {
          has_1003_enhanced
          feature_setting {
            show_borrower_dashboard_calcs_and_sidemenu
          }
        }
      }
    }
  }
`

export default {
  name: 'BorrowerApp',
  components: {
    BorrowerEditAccountDialog,
    BorrowerShareAppBySms,
    SessionTimeout,
    SidenavTile,
    DefaultCompanyLogo,
    BrowserDeprecationWarning,
    newLoanAppDialog
  },
  filters: {},
  mixins: [StoreGenerator, brandThemeMixin],
  data () {
    return {
      sidenavOptions: [
        {
          title: 'My Loan',
          icon: 'sn-icon-document-check',
          clickAction: () => {
            let url
            if (this.$route.params.loanAppGuid || this.$route.params.loanGuid) {
              return
            } else if (!this.$route.params.loanOrLoanApp || !this.$route.params.guid) {
              if (this.$route.path.endsWith('/borrower/dashboard')) return
              url = '/borrower/dashboard'
            } else {
              url = `/borrower/dashboard/${this.$route.params.loanOrLoanApp}/${this.$route.params.guid}`
            }
            this.$router.push({ path: url })
          },
          shouldShow: () => {
            return !this.$route.path.includes('custom_form') && !this.$route.path.includes('/borrower/loans') && store.currentUser
          },
          isActive: () => {
            return (this.$route.path.endsWith('/borrower/dashboard') || this.$route.path.includes('/borrower/dashboard/loan') || this.$route.path.includes('/borrower/dashboard/loan_app'))
          }
        },
        {
          title: 'Calculator',
          icon: 'sn-icon-calculator',
          clickAction: () => {
            if (this.$route.path.includes('/borrower/dashboard/calculator')) return
            if (this.$route.params.loanAppGuid) {
              this.$router.push(`/borrower/dashboard/calculator/loan_app/${this.$route.params.loanAppGuid}`)
            } else if (this.$route.params.loanGuid) {
              this.$router.push(`/borrower/dashboard/calculator/loan/${this.$route.params.loanGuid}`)
            } else {
              this.$router.push('/borrower/dashboard/calculator')
            }
          },
          shouldShow: () => {
            return this.$store.desktopSidenavEnabled && !this.$route.path.includes('/borrower/loans') && !this.$route.path.includes('/custom_form')
          },
          isActive: () => {
            return (this.$route.path.includes('/borrower/dashboard/calculator'))
          }
        },
        {
          dynamicTitle: () => {
            return this.getTheAppText
          },
          icon: 'sn-icon-mobile-phone2',
          clickAction: () => {
            if (this.$vuetify.breakpoint.mdAndDown) this.$store.sidenav = false
            if (this.$vuetify.breakpoint.mdAndDown) {
              this.store.smallGetTheAppDialog = !this.store.smallGetTheAppDialog
            } else {
              this.store.getTheAppDialog = !this.store.getTheAppDialog
            }
          },
          shouldShow: () => {
            return this.store?.borrowerWebContent?.showMobileContentOnLandingPage || !(this?.$route?.query?.from_mobile_share === 'true' && this.store?.servicer?.feature_setting_force_mobile_web_signup_from_sharelink_enabled)
          },
          isActive: () => { return false }
        },
        {
          dynamicTitle: () => {
            return this.splashHeaderLanguage
          },
          icon: 'sn-icon-earth',
          clickAction: () => {
            if (this.$vuetify.breakpoint.mdAndDown) this.$store.sidenav = false
            this.changeLanguage(this.store.borrowerWebContent.splashHeaderSwitchLocale)
          },
          shouldShow: () => {
            return this.viewLocaleTab
          },
          isActive: () => { return false }
        },
        {
          divider: true,
          shouldShow: () => {
            return this.$vuetify.breakpoint.mdAndDown
          }
        },
        {
          dynamicTitle: () => {
            return this.computedMenuNewLoanAppText
          },
          icon: 'sn-icon-plus-circle',
          clickAction: () => {
            if (this.$vuetify.breakpoint.mdAndDown) this.$store.sidenav = false
            this.shouldConfirmNewLoanApp ? this.confirmNewLoanApp = true : this.createNewLoanApp()
          },
          shouldShow: () => {
            return this.$vuetify.breakpoint.mdAndDown && store.currentUser && this.has1003Enhanced
          },
          isActive: () => { return false }
        },
        {
          dynamicTitle: () => {
            return this.splashHeaderNavEditAccount
          },
          icon: 'sn-icon-person',
          clickAction: () => {
            if (this.$vuetify.breakpoint.mdAndDown) this.$store.sidenav = false
            this.store.editAccountDialog = true
          },
          shouldShow: () => {
            return this.$vuetify.breakpoint.mdAndDown && this.$store.desktopSidenavEnabled && store.currentUser
          },
          isActive: () => { return false }
        },
        {
          dynamicTitle: () => {
            return this.splashHeaderNavSignOut
          },
          icon: 'sn-icon-exit-left',
          clickAction: () => {
            window.location.assign('/logout')
          },
          shouldShow: () => {
            return this.$vuetify.breakpoint.mdAndDown && this.$store.desktopSidenavEnabled && store.currentUser
          },
          isActive: () => { return false }
        },
        {
          dynamicTitle: () => {
            return this.splashHeaderNavLogin
          },
          icon: 'sn-icon-exit-left',
          clickAction: () => {
            this.signIn()
          },
          shouldShow: () => {
            return !this.$route.path.includes('login') && !store.currentUser
          },
          isActive: () => { return false }
        }
      ],
      store: this.$store,
      totalActiveLoans: 0,
      totalLoans: 0,
      activeNonHiddenLoansCount: 0,
      nonHiddenLoansCount: 0,
      totalUnlinkedLoanApps: 0,
      has1003Enhanced: false,
      googlePlay: googlePlay,
      appleStore: appleStore,
      confirmNewLoanApp: false
    }
  },
  apollo: {
    loanNumber: {
      query: gql`
        query loanNumber($loanGuid: ID!) {
          loan(guid: $loanGuid) {
            loan_number
          }
        }
      `,

      skip () {
        return !this.$route.params.loanGuid
      },

      variables () {
        return {
          loanGuid: this.$route.params.loanGuid
        }
      },

      update ({ loan: { loan_number: ln } }) {
        return ln
      }
    },
    currentUserMeta: {
      query: currentUserMetaQuery,

      manual: true,

      result ({ data }) {
        const { user } = data
        if (user) {
          const { app_user: appUser } = user
          if (appUser) {
            this.totalActiveLoans = appUser ? appUser.total_active_loans : 0
            this.totalLoans = appUser ? appUser.loans_count : 0
            this.activeNonHiddenLoansCount = appUser ? appUser.active_non_hidden_loans_count : 0
            this.nonHiddenLoansCount = appUser ? appUser.non_hidden_loans_count : 0
            if (appUser.servicer_profile.has_1003_enhanced) this.has1003Enhanced = true
            this.totalUnlinkedLoanApps = appUser ? appUser.unlinked_user_loan_apps_count : 0
            this.$store.desktopSidenavEnabled = appUser ? appUser.servicer_profile.feature_setting.show_borrower_dashboard_calcs_and_sidemenu : false
            if (this.$store.desktopSidenavEnabled && this.$vuetify.breakpoint.lgAndUp && this.routePathShouldShowSidenav) this.$store.sidenav = true
          }
        }
      },
      error ({ error }) {
        console.error(error)
      }
    }
  },
  computed: {
    headerPadding () {
      if (this.$route.path.includes('/dashboard') && this.$vuetify.breakpoint.mdAndUp) return 'px-72'
      return ''
    },
    showApp () {
      return this.store?.borrowerWebContent?.showMobileContentOnLandingPage
    },
    routePathShouldShowSidenav () {
      return (this.$route.path.includes('/borrower/dashboard/loan') || this.$route.path.includes('/borrower/dashboard/loan_app') || this.$route.path.includes('/borrower/dashboard/calculator') || this.$route.path.includes('login'))
    },
    pageTitle () {
      if (!this.$apollo || this.$apollo.loading) return '' // prevents text swapping on page load
      if (this.$route.path.startsWith('/borrower/dashboard/calculator')) return 'Calculator'
      return this.loanNumber ? `Viewing Loan ${this.loanNumber}` : this.store.borrowerWebContent && this.store.borrowerWebContent.myHomeLoan
    },
    computedLogoLink () {
      if (!this.store.currentUser && (!this.$route.path.includes('/borrower/signup') || !this.$route.path.includes('/borrower/onboarding')) && (this.$route.query.code || this.store.activationCode)) return '/borrower/signup/' + (this.$route.query.code || this.store.activationCode)
      else if (this.store.currentUser && this.store.borrowerWebContent && this.store.borrowerWebContent.logoUrlLink) return this.store.borrowerWebContent.logoUrlLink
      else return ''
    },
    computedLogoImageUrl () {
      if (this.store.borrowerWebContent) return this.store.borrowerWebContent.logoUrl || ''
      else return null
    },
    computedMenuNewLoanAppText () {
      return this.store?.borrowerWebContent?.menuNewLoanAppText ? this.store.borrowerWebContent.menuNewLoanAppText : 'New Application'
    },
    getTheAppText () {
      return this.store?.borrowerWebContent?.getTheAppText ? this.store.borrowerWebContent.getTheAppText : 'Get The App'
    },
    supportAvailable () {
      return this.store.support && this.store.support.email && this.store.support.phone && this.store.support.hours
    },
    userAgent: function () {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera

      if (/windows phone/i.test(userAgent)) {
        return 'Windows Phone'
      } else if (/android/i.test(userAgent)) {
        return 'Android'
      } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return 'iOS'
      }

      return 'unknown'
    },
    viewMyLoanTab () {
      if (this.$route.path.includes('/borrower/loans')) return false
      if (this.store && this.store.currentUser && this.store.currentUser.feature_setting_borrower_show_inactive_loans_enabled) {
        if (this.store.currentUser.feature_setting_hide_hidden_loans_from_borrower) {
          return (this.nonHiddenLoansCount + this.totalUnlinkedLoanApps > 1) || (this.nonHiddenLoansCount === 1 && this.activeNonHiddenLoansCount === 0)
        } else {
          return this.totalLoans + this.totalUnlinkedLoanApps > 1 || (this.totalLoans === 1 && this.totalActiveLoans === 0)
        }
      } else {
        return this.totalActiveLoans + this.totalUnlinkedLoanApps > 1
      }
    },
    viewLocaleTab () {
      if (!this.isSigningUp) return false
      else return (this.store && this.store?.borrowerWebContent?.splashHeaderAllowLocale)
    },
    splashHeaderNavGetTheApp () {
      return this.store.borrowerWebContent?.splashHeaderNavGetTheApp
    },
    splashHeaderNavSupport () {
      return this.store.borrowerWebContent?.splashHeaderNavSupport
    },
    splashDialogHelpAndSupportTitle () {
      return this.store.splashDialogHelpAndSupportTitle?.splashDialogHelpAndSupportTitle
    },
    textMeTheApp () {
      return this.store.textMeTheApp?.textMeTheApp
    },
    splashDialogCallSupport () {
      return this.store.splashDialogCallSupport?.splashDialogCallSupport
    },
    splashDialogAvailability () {
      return this.store.splashDialogAvailability?.splashDialogAvailability
    },
    splashDialogEmailSupport () {
      return this.store.splashDialogEmailSupport?.splashDialogEmailSupport
    },
    splashDialogEmailSupportAt () {
      return this.store.splashDialogEmailSupportAt?.splashDialogEmailSupportAt
    },
    splashHeaderNavLogin () {
      return this.store.borrowerWebContent?.splashHeaderNavLogin
    },
    splashHeaderNavMyAccount () {
      return this.store.borrowerWebContent?.splashHeaderNavMyAccount
    },
    splashHeaderNavEditAccount () {
      return this.store.borrowerWebContent?.splashHeaderNavEditAccount
    },
    splashHeaderNavSignOut () {
      return this.store.borrowerWebContent?.splashHeaderNavSignOut
    },
    splashHeaderLanguage () {
      return this.store.borrowerWebContent?.splashHeaderLanguage
    },
    splashHeaderMyLoans () {
      return this.store.borrowerWebContent?.splashHeaderMyLoans
    },
    showDesktopSidenav () {
      return this.$store.desktopSidenavEnabled && !this.$route.path.includes('/borrower/loans') && !this.$route.path.includes('custom_form')
    },
    shouldConfirmNewLoanApp () {
      if (this.activeNonHiddenLoansCount + this.totalUnlinkedLoanApps > 0) {
        return true
      } else {
        return false
      }
    },
    hideNewApplicationButton () {
      return this.$store?.borrowerWebContent?.hideNewApplicationButton
    }
  },
  // *** The 'created ()' function for the borrowerApp lives in ./borrowerAppStoreGenerator.js ***
  methods: {
    signOutAndGoToLogin () {
      window.location.assign('/logout')
    },
    switchLoans () {
      // Logic intentionally separated to avoid a potential race condition where this.store.servicer isn't populated,
      // so we want to fail to /homehub instead of to /borrower/loans.
      if (this.store && this.store.servicer && !this.store.servicer.feature_setting_use_loan_context_for_borrower) {
        this.$router.push({ path: '/borrower/loans' })
      } else {
        location.href = '/homehub'
      }
    },
    async createNewLoanApp () {
      this.$snackbard.loading()
      const newGuid = await this.newLoanAppGuid()
      this.$snackbard.cancel()
      const path = `${this.$options.filters.base1003Url(this.store)}/custom_form?type=loan_app&guid=${newGuid}`
      if (this.$refs.modal.sac && this.$store?.activationCode.toUpperCase() !== this.$refs.modal.sac.toUpperCase()) {
        window.location.assign(`/sessions/switch_context?sac=${encodeURIComponent(this.$refs.modal.sac)}&path=${encodeURIComponent(path)}`)
      } else {
        window.location.assign(path)
      }
    },
    goToInstallLink: function () {
      window.location.assign('/install/app/' + this.store.activationCode)
    },
    changeLanguage: function (locale) {
      window.location.assign('/' + locale + '/borrower/signup/' + this.store.activationCode)
    },
    signIn: function () {
      window.location.assign('/borrower/login/' + (this.store.activationCode || ''))
    },
    sideNavItemTitle (item) {
      if (item.title) return item.title
      else if (!item.divider) return item.dynamicTitle()
      else return ''
    },
    newLoanAppGuid () {
      return new Promise(resolve => {
        this.$apollo.mutate({
          mutation: gql`
            mutation($appUserGuid: ID!, $servicerProfileId: ID) {
              create_user_loan_app(app_user_guid: $appUserGuid, servicer_profile_id: $servicerProfileId, create_user_loan_app_link: false) {
                user_loan_app {
                  id
                  guid
                  created_at
                }
              }
            }
          `,
          variables: {
            appUserGuid: this.store.currentUser.app_user.guid,
            servicerProfileId: this.$refs.modal.servicerProfileId
          }
        }).then(({ data }) => {
          const { create_user_loan_app: { user_loan_app: { guid } } } = data
          resolve(guid) // just send back the guid so that we can redirect to the new loan app
        })
      })
    },
    updateStore (newSac) {
      this.getInformationFromCodeAndSetUpStore(newSac)
    }
  }
}
</script>

<style scoped>
  #header1 {
    height: 6px;
  }
  #header2 {
    height: 12px;
  }
  #super-user-banner {
    margin-top:0px;
    height:20px;
    background-color:red;
    text-align:center;
  }

  #super-user-banner a {
    font-weight:bold;
    color:white;
  }
  .borrower-task-nav {
    height: 90px;
  }
  .ml-16px {
    margin-left: 16px;
  }
  .full-height {
    max-height: 100%;
  }
</style>

<style>
  .btn--xlarge {
    font-size: 15px;
    height: 55px;
  }
  .container { max-width:1100px; }
  h1, h2, h3, h4, h5, h6 {
    font-weight: 100;
    line-height: 1.5em;
  }
  h3 {
    font-size: 40px;
  }
  .hoverable:hover {
    cursor: pointer;
    /*text-decoration: underline;*/
  }
  hr {
    border-top: 1px solid #eee;
  }

  .mt--5 { margin-top: -5px; }
  .pl-4_5 { padding-left: 36px !important; }
  .px-72 {
    padding-left: 72px;
    padding-right: 72px;
  }
  .smaller {
    font-size: 1.2em;
    line-height:2.1em;
  }
  .sn--gray { color: #575757; }
  .table.row { min-height: 50px; }
  .toolbar {
    height: 100px;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .toolbar__item .menu__activator .btn--dark {
    color: #808080 !important;
    font-weight: bold;
  }
  /*.picker--date__table .btn--current .btn--active {
    padding: 0 5px !important;
  }*/
  .text--centered { text-align:center; }
  .uppercase { text-transform: uppercase; }
  .xs0_75 {
    flex-basis: 6.23%;
    max-width: 6.23%;
  }
  .xs1_5 {
    flex-basis: 12.5%;
    max-width: 12.5%;
  }
  .v-toolbar__content {
    padding-left: 0;
    padding-right: 0;
  }
  .fix-vuetify-toolbar-height {
    height: 64px !important;
  }
  .barlow-font {
    font-family: Barlow, sans-serif !important;
  }
  .barlow-condensed-font {
    font-family: Barlow Condensed, sans-serif !important;
  }
</style>
