<template>
  <v-dialog
    v-model="value"
    fullscreen
    transition="fade-transition"
  >
    <div
      class="sn-modal-fs sn-background-gray-01 d-flex flex-column overflow-y-auto"
      style="height: 100%;"
    >
      <div class="d-flex flex-row">
        <v-btn
          flat
          icon
          color="black"
          style="position: absolute;"
          :style="$vuetify.breakpoint.smAndDown ? 'right: 16px; top: 16px;' : 'right: 25px; top: 25px;'"
          class="ma-0"
          @click="$emit('input', false)"
        >
          <v-icon
            color="black"
            size="22"
          >
            {{ $vuetify.icons['sn-icon-exit'] }}
            exit
          </v-icon>
        </v-btn>
        <v-layout
          row
          wrap
          d-block
        >
          <div class="modal-title mr-6 ml-6">
            <h1
              class="sn-h1 pt-6 pb-4"
              data-cy="modal-title"
            >
              {{ titleText }}
            </h1>
          </div>
          <v-container class="pa-0 d-flex justify-center sn-background-gray-01">
            <v-layout
              row
              wrap
              class="ma-4"
              style="height: 100%; max-width: 656px; margin: 0 auto;"
            >
              <v-flex
                v-if="store.allowBorrowerToSeeLoansWithMultipleLos && servicerProfiles.length > 1"
                xs12
                class="pa-0 mb-12"
              >
                <div class="sn-h5 pb-3">
                  Who are you working with?
                </div>
                <v-radio-group
                  v-model="servicerProfileId"
                >
                  <v-radio
                    v-for="(servicer, index) in servicerProfiles"
                    :key="`servicer-${index}`"
                    :value="servicer.id"
                  >
                    <template v-slot:label>
                      <v-avatar
                        class="ml-4 mr-1"
                        color="white"
                      >
                        <img
                          v-if="servicer.profile_image_url"
                          :src="servicer.profile_image_url"
                          :alt="servicer.name"
                        >
                        <span v-else>{{ initials(servicer.name) }}</span>
                      </v-avatar>
                      <v-layout
                        row
                        wrap
                        class="ml-2"
                      >
                        <v-flex
                          xs12
                          class="v-list-item__title"
                        >
                          {{ servicer.name }}
                        </v-flex>
                        <v-flex
                          xs12
                          class="v-list-item__subtitle"
                        >
                          {{ location(servicer) }}
                        </v-flex>
                      </v-layout>
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-flex>
              <v-flex
                xs12
                class="px-0 pt-0 pb-4"
              >
                <div class="sn-font-primary sn-font-weight-regular">
                  {{ bodyText }}
                </div>
              </v-flex>
              <v-flex
                xs12
                class="px-0 pb-4"
              >
                <v-checkbox
                  v-model="confirmed"
                  color="black"
                  :label="checkboxText"
                  class="mt-0 pt-0 sn-body-1"
                />
              </v-flex>
              <v-layout
                row
                justify-center
                class="pb-2 px-0"
              >
                <sn-btn
                  :disabled="disableConfirm"
                  :data-cy="confirmButtonText + '-btn'"
                  @click="clickStartApplicationButton"
                >
                  {{ confirmButtonText }}
                </sn-btn>
              </v-layout>
            </v-layout>
          </v-container>
        </v-layout>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: 'NewLoanAppDialog',
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      confirmed: false,
      servicerProfileId: null,
      sac: null,
      store: this.$store,
      buttonClicked: false
    }
  },
  computed: {
    titleText () {
      return this.store?.borrowerWebContent ? this.store.borrowerWebContent.newLoanAppDialogTitle : 'Ready for a new loan?'
    },
    bodyText () {
      return this.store?.borrowerWebContent ? this.store.borrowerWebContent.newLoanAppDialogText : 'You are about to start an application for a new loan that will be separate from any existing or previous loans. Are you sure you want to apply for an additional loan?'
    },
    checkboxText () {
      return this.store?.borrowerWebContent ? this.store.borrowerWebContent.newLoanAppDialogCheckbox : 'Yes, I want to apply for a new loan'
    },
    confirmButtonText () {
      return this.store?.borrowerWebContent ? this.store.borrowerWebContent.newLoanAppDialogConfirmButton : 'Start application'
    },
    servicerProfiles () {
      return this.store?.currentUser?.servicer_profiles || []
    },
    disableConfirm () {
      if (this.store?.allowBorrowerToSeeLoansWithMultipleLos && this.servicerProfiles.length > 1) {
        return !this.confirmed || !this.servicerProfileId
      } else {
        return !this.confirmed
      }
    }
  },
  watch: {
    servicerProfileId (val) {
      this.sac = this.servicerProfiles.find(s => s.id === val).sac
    }
  },
  methods: {
    initials (name) {
      return name?.split(' ')?.map(i => i.charAt(0))?.join('')?.toUpperCase() ?? ''
    },
    location (servicer) {
      if (servicer.city) {
        return `${servicer.city}, ${servicer.state}`
      } else if (servicer.state) {
        return servicer.state
      } else {
        return ''
      }
    },
    clickStartApplicationButton () {
      if (!this.buttonClicked) {
        this.buttonClicked = true
        this.$emit('confirm')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-title {
  text-align: center;
  flex-grow: 1;
}

::v-deep {
  .v-label {
    text-transform: none !important;
    font-weight: inherit !important;
    color: inherit !important;
  }
}

.pt-16 {
  padding-top: 16px;
}

.pb-24 {
  padding-bottom: 24px;
}

::v-deep {
  .v-radio {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }
}
</style>
