import { Promise } from 'es6-promise'
import axios from '~/util/axios'

export default {
  create (email, pass) {
    return axios.post('/api/v1/sessions', {
      email: email,
      password: pass
    })
  },
  destroy () {
    return axios.delete('/api/v1/sessions', {})
  },
  setSAC (id) {
    return axios.post('/api/v1/sessions/sac', {
      sac_id: id
    })
  },
  getSAC () {
    if (document.head.querySelector('[name=csrf-token]')) {
      return axios.get('/sessions/get_sac')
    } else {
      return new Promise(resolve => {
        resolve('No CSRF Token')
      })
    }
  },
  getCurrentUser () {
    return axios.get('/sessions/get_current_user')
  },
  ping () {
    return axios.get('/sessions/ping')
  }
}
