import snUtilService from '../../services/snUtilService.js'
export default {
  data () {
    return {
      sn_shareBySmsMixin_sendToMobileNumber: null,
      sn_shareBySmsMixin_errors: [],
      sn_shareBySmsMixin_showSnackbarMessages: true
    }
  },
  computed: {
    sn_paginationMixin_sortBy () {
      return this.sn_paginationMixin_sortColumn + ' ' + (this.sn_paginationMixin_descending ? 'DESC' : 'ASC')
    }

  },
  methods: {
    sn_shareBySmsMixin_shareAppBySms (sac, phoneNumber, slug = 'share_app_sms') {
      var that = this
      if (phoneNumber !== null && phoneNumber.replace(/\D/g, '').length === 10) {
        var digits = phoneNumber.replace(/\D/g, '')
        if (this.sn_shareBySmsMixin_showSnackbarMessages) this.$snackbard.loading({ text: this.store.borrowerWebContent.splashSendingTextMsg })
        snUtilService.shareAppBySms(digits, sac, slug)
          .then(response => {
            phoneNumber = null
            if (this.sn_shareBySmsMixin_showSnackbarMessages) this.$snackbard.cancel({ text: this.store.borrowerWebContent.splashSentTextMsg })
          })
          .catch(e => {
            this.$sentry.captureException(e)
            console.log(e)
            that.sn_shareBySmsMixin_errors.push(e)
          })
      } else if (this.sn_shareBySmsMixin_showSnackbarMessages) {
        this.$snackbard.show({ text: this.store.borrowerWebContent.splashPhoneInputError })
      }
    }
  }
}
