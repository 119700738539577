import { homeInsuranceSuggestedAddressQuery } from './queries.gql'

import { merge } from 'lodash'

export const resolvers = {
  Mutation: {
    updateHomeInsuranceSuggestedAddress: (_, args, { cache }) => {
      const data = merge(cache.readQuery({ query: homeInsuranceSuggestedAddressQuery }), { homeInsurance: { suggestedAddress: args } })
      return cache.writeData({ data })
    }
  },
  Query: {}
}

export const defaults = {
  homeInsurance: {
    __typename: 'HomeInsurance',
    suggestedAddress: {
      __typename: 'Address',
      bestGuessStreetNumber: '',
      bestGuessStreetName: '',
      street: '',
      city: '',
      state: '',
      zip: '',
      bestGuessIsCondo: false,
      bestGuessIsManufactured: false
    }
  }
}
