import checkWithPencil from '~/assets/images/sn-illustration-check-with-pencil.snvg'
import { createTransactionMutation } from '../nexusPay/nexusPay.gql'

export default {
  components: {
    checkWithPencil
  },

  props: {
    value: {
      type: Object,
      required: true
    },
    performSubmit: {
      type: Boolean,
      required: false,
      default: false
    },
    loView: {
      type: Boolean,
      required: false,
      default: false
    },
    showAnimation: {
      type: Boolean,
      required: false,
      default: false
    },
    showItemization: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  data () {
    return {
      creditTypeSelected: true,
      bankTypeSelected: false,
      cardNumber: null,
      expirationDate: null,
      securityCode: null,
      amountDue: null,
      streetAddress: null,
      zipCode: null,
      creditRules: {
        required: value => this.bankTypeSelected || !!value || 'Required',
        utf8: value => this.isUTF8Friendly(value) || 'Invalid character(s) used',
        valid: value => this.bankTypeSelected || this.getCardType(value) !== '' || 'Invalid credit card',
        cardLength: value => {
          if (this.bankTypeSelected) return true

          if (this.getCardType(value) === 'AMEX') {
            return value?.replaceAll(' ', '').length === 15 || 'Must be 15 numbers'
          } else {
            return value?.replaceAll(' ', '').length === 16 || 'Must be 16 numbers'
          }
        },
        securityCodeLength: value => {
          if (this.bankTypeSelected) return true

          if (this.creditCardType === 'AMEX') {
            return value?.replaceAll(' ', '').length === 4 || 'Must be 4 numbers'
          } else {
            return value?.replaceAll(' ', '').length === 3 || 'Must be 3 numbers'
          }
        },
        expirationDate: value => this.bankTypeSelected || this.validateExpirationDate(value),
        zipCode: value => !value ? 'Required' : this.bankTypeSelected || /^\d{5}(?:[-\s]\d{4})?$/.test(value) ? true : 'Invalid Zip Code'
      },
      bankRules: {
        required: value => this.creditTypeSelected || !!value || 'Required',
        utf8: value => this.isUTF8Friendly(value) || 'Invalid character(s) used',
        firstName: value => !!value || 'Enter first name',
        lastName: value => !!value || 'Enter last name'
      },
      accountType: null,
      firstName: '',
      lastName: '',
      routingNumber: null,
      accountNumber: null,
      formValid: false,
      focusedField: null,
      errorMessage: null,
      paymentSuccess: false,
      isSubmitting: false,
      isPaidSuccess: false
    }
  },

  computed: {
    largeCardSizing () {
      if (this.$store.servicer?.feature_setting_borrower_home_page_refresh) {
        return 5
      } else {
        return this.amountDetails.length ? 5 : 3
      }
    },
    largeCardSizingMethods () {
      if (this.$store.servicer?.feature_setting_borrower_home_page_refresh) {
        return 6
      } else {
        return this.amountDetails.length ? 6 : 8
      }
    },
    refreshedDashboard () {
      return this.$store.servicer?.feature_setting_borrower_home_page_refresh
    },
    illustrationSize () {
      return this.$store.servicer?.feature_setting_borrower_home_page_refresh ? '120' : '160'
    },
    instructions () {
      return this.value.description
    },
    currentBalance () {
      return this.currencyHelper(this.value.amount)
    },
    amountDetails () {
      return this.value.amountDetails || []
    },
    theme () {
      return this.$vuetify.theme
    },
    primaryColor () {
      return this.theme?.themes?.light?.primary ?? this.theme.primary
    },
    primaryColorRGBObject () {
      return this.hexToRgb(this.theme?.themes?.light?.primary ?? this.theme.primary)
    },
    primaryColorWithOpacity () {
      return `rgba(${this.primaryColorRGBObject.r}, ${this.primaryColorRGBObject.g}, ${this.primaryColorRGBObject.b}, 0.05)`
    },
    creditCardIcon () {
      if (this.shouldHighlightCredit) return 'sn-icon-check-circle'
      return 'sn-icon-credit-card'
    },
    bankIcon () {
      if (this.shouldHighlightBank) return 'sn-icon-check-circle'
      return 'sn-icon-bank'
    },
    vectorCSSProps () {
      return {
        '--primary-color': this.$vuetify.theme?.themes?.light?.primary ?? this.$vuetify.theme.primary,
        width: this.$vuetify.breakpoint.mdAndDown ? '0' : '120%',
        marginTop: '-25px'
      }
    },
    pencilCSSProps () {
      return {
        '--primary-color': this.$vuetify.theme?.themes?.light?.primary ?? this.$vuetify.theme.primary,
        height: '160px',
        width: '160px'
      }
    },
    creditCardType () {
      return this.getCardType(this.cardNumber)
    },
    cardMask () {
      if (this.creditCardType === 'AMEX') {
        return '#### ###### #####'
      }
      return '#### #### #### ####'
    },
    paymentAmount () {
      return this.currentBalance
    },
    canSubmitPayment () {
      return this.formValid && !this.isPaidSuccess && this.focusedFieldValid && (this.creditTypeSelected || this.bankTypeSelected)
    },
    achAllowed () {
      return this.value.achAllowed
    },
    creditAllowed () {
      return this.value.creditAllowed
    },
    verificationLanguage () {
      return this.value.verificationLanguage || this.value.merchant?.verificationLanguage
    },
    shouldHighlightCredit () {
      return this.creditTypeSelected && this.creditAllowed && this.achAllowed
    },
    shouldHighlightBank () {
      return this.bankTypeSelected && this.achAllowed && this.creditAllowed
    },
    vuetify () {
      return this.$vuetify
    },
    focusedFieldValid () {
      // We are validating each filed on blur so everything else could be valid but
      // If the field that is focused isn't also valid we shouldn't allow the form
      // To be submitted
      switch (this.focusedField) {
        case 'cardNumber':
          return this.creditRules.required(this.cardNumber) === true && this.creditRules.cardLength(this.cardNumber) === true && this.creditRules.valid(this.cardNumber) === true
        case 'expirationDate':
          return this.creditRules.required(this.expirationDate) === true && this.creditRules.expirationDate(this.expirationDate) === true
        case 'securityCode':
          return this.creditRules.required(this.securityCode) === true && this.creditRules.securityCodeLength(this.securityCode) === true
        case 'streetAddress':
          return this.creditRules.required(this.streetAddress) === true
        case 'zipCode':
          return this.creditRules.zipCode(this.zipCode) === true
        case 'accountType':
          return this.bankRules.required(this.accountType) === true
        case 'firstName':
          return this.bankRules.required(this.firstName) === true
        case 'lastName':
          return this.bankRules.required(this.lastName) === true
        case 'routingNumber':
          return this.bankRules.required(this.routingNumber) === true
        case 'accountNumber':
          return this.bankRules.required(this.accountNumber) === true
        default:
          // There is no field focused so we should return true
          return true
      }
    }
  },

  watch: {
    creditTypeSelected (newVal) {
      if (newVal) {
        this.bankTypeSelected = false
      }
    },
    bankTypeSelected (newVal) {
      if (newVal) {
        this.creditTypeSelected = false
      }
    },
    currentBalance (newVal) {
      this.$emit('setCustomPrimaryBtnText', `PAY ${newVal}`)
    },
    performSubmit (newVal) {
      if (newVal && !this.isSubmitting && !this.isPaidSuccess) {
        this.showLoader([{ text: 'Hang tight, we\'re submitting your payment', classes: 'sn-callout sn-font-weight-regular' }])
        this.initiatePayment()
      }
    },
    paymentAmount (newVal) {
      this.$emit('setCustomPrimaryBtnText', `PAY ${(newVal)}`)
    },
    canSubmitPayment (newVal) {
      this.$emit('allow-submit', newVal)
    }
  },

  mounted () {
    this.$emit('setCustomPrimaryBtnText', `PAY ${this.paymentAmount}`)
    if (!this.achAllowed) {
      this.creditTypeSelected = true
    } else if (!this.creditAllowed) {
      this.bankTypeSelected = true
    }
  },

  methods: {
    isUTF8Friendly (str) {
      try {
        new TextDecoder('utf-8').decode(new Uint8Array([...str].map(c => c.charCodeAt(0))))
        return true
      } catch (error) {
        return false
      }
    },
    validateExpirationDate (val) {
      if (!/^(0[1-9]|1[0-2])\/(\d{2}|[1-9]\d{3})$/.test(val)) return 'Must be mm/yy'
      const now = new Date(Date.now())
      const valYear = val.slice(3)
      const formattedYear = valYear.length === 4 ? valYear : now.getFullYear().toString().slice(0, 2) + valYear
      const fullDateString = val.slice(0, 3) + '01/' + formattedYear
      const valDateObj = new Date(fullDateString)
      if (valDateObj <= now) return 'Expiration date has passed'
      const tenYearsAsMilliseconds = 315360000000
      if (valDateObj - now >= tenYearsAsMilliseconds) return 'Enter a valid expiration date'
      return true
    },
    async initiatePayment () {
      let paymentData = null
      this.isSubmitting = true
      if (this.creditTypeSelected) {
        paymentData = {
          payment_type: 'credit',
          card: {
            card_number: this.cardNumber.replace(/\s+/g, ''),
            card_security_code: this.securityCode,
            expiry_month: this.expirationDate.slice(0, 2),
            expiry_year: this.expirationDate.slice(-2)
          },
          billing_address: {
            line1: this.streetAddress,
            postal_code: this.zipCode
          },
          payment: {
            amount: this.paymentAmount.replace(/[$,]/g, ''),
            invoice_number: this.value.invoiceNumber
          },
          merchant: this.value.merchant,
          taskGuid: this.value.guid
        }
      } else if (this.bankTypeSelected) {
        paymentData = {
          payment_type: 'ach',
          check: {
            account_type: this.accountType.replace(/\sAccount$/, ''),
            account_number: this.accountNumber,
            routing_number: this.routingNumber
          },
          customer: {
            first_name: this.firstName,
            last_name: this.lastName
          },
          payment: {
            amount: this.paymentAmount.replace(/[$,]/g, ''),
            invoice_number: this.value.invoiceNumber
          },
          merchant: this.value.merchant,
          taskGuid: this.value.guid
        }
      }
      if (paymentData) {
        this.saveTransaction(paymentData)
      } else {
        this.$emit('submit-complete', false, 'Payment type not yet implemented')
        this.isSubmitting = false
      }
    },
    saveTransaction (paymentData) {
      this.$apollo.mutate({
        mutation: createTransactionMutation,
        variables: {
          transaction: {
            nexusPayTaskId: this.value.id,
            transactionType: paymentData.payment_type,
            paymentData: window.btoa(JSON.stringify(paymentData))
          }
        }
      }).then(({ data }) => {
        const result = data?.createNexusPayTransaction
        if (result.nexusPayTransaction) {
          this.paymentSuccess = true
          // completes for admin dashboard
          this.$emit('cancel-submit')
          // completes for borrower dashboard
          this.isPaidSuccess = true
          this.$emit('setCustomPrimaryBtnText', 'PAID')
          setTimeout(this.handleTransition, 3500)
        } else if (result.message) {
          this.$emit('display-error', result.message)
        } else {
          this.$emit('display-error', 'An error occurred - please contact support.')
        }
        this.hideLoader()
        this.isSubmitting = false
      }).catch(e => {
        this.$emit('display-error', e.message.replace('GraphQL error: ', ''))
        this.$emit('hideMobileLoader')
        this.hideLoader()
        this.isSubmitting = false
        console.log(e)
      })
    },

    handleTransition () {
      this.$emit('submit-complete', true, false)
      if (this.$store.inMobileApp) {
        this.finishAndClose()
      }
    },
    currencyHelper (amount) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      })

      return formatter.format(amount)
    },
    hexToRgb (hex) {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
      return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      } : null
    },
    getCardType (number) {
      // visa
      if (!number) return
      let re = new RegExp('^4')
      if (number.match(re) != null) {
        return 'Visa'
      }
      // Mastercard
      if (/^5[1-5][0-9]+|^(222[1-9]|22[3-9]\\d|2[3-6]\\d{2}|27[0-1]\\d|2720)[0-9]+$/.test(number.replaceAll(' ', ''))) {
        return 'Mastercard'
      }

      // AMEX
      re = new RegExp('^3[47]')
      if (number.match(re) != null) {
        return 'AMEX'
      }

      // Discover
      re = new RegExp('^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)')
      if (number.match(re) != null) {
        return 'Discover'
      }

      return ''
    },
    enableCredit () {
      if (this.achAllowed && this.creditAllowed) {
        this.creditTypeSelected = !this.creditTypeSelected
      }
    },
    enableBank () {
      if (this.achAllowed && this.creditAllowed) {
        this.bankTypeSelected = !this.bankTypeSelected
      }
    },
    finishAndClose () {
      if (this.$store?.servicer?.feature_setting_borrower_task_ui_refresh_enabled) {
        this.$emit('task-complete')
      } else {
        this.$emit('navigation', 'close')
      }
    },
    showLoader (messages) {
      this.$emit('showLoader', messages)
    },
    hideLoader (stopImmediate) {
      this.$emit('hideLoader', stopImmediate)
    },
    initiateMobilePayment () {
      this.$emit('showLoader', [{ text: 'Hang tight, we\'re processing your payment', classes: 'sn-callout sn-font-weight-regular' }])
      this.initiatePayment()
    }
  }
}
