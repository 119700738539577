<template>
  <div id="phone-input">
    <v-text-field
      :id="id"
      v-model="pn"
      :autofocus="autofocus"
      :disabled="disabled"
      :error-messages="computedErrors"
      :hint="hint"
      :required="required"
      :label="label"
      :placeholder="placeholder"
      :title="label? label : placeholder"
      :error="error"
      class="sel_phone"
      :class="{'asterisk': asterisk}"
      inputmode="tel"
      pattern="\d*"
      maxlength="14"
      autocomplete="nope"
      data-cy="phone-number-input"
      @blur="validatePhoneNumber(); $emit('blur', $event)"
      @change="validatePhoneNumber"
      @focus="localErrors = []; $emit('focus', $event)"
      @keydown.native="preventChar"
      @keyup.native="disableCopy"
      @paste.native="parsePaste"
    />
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'PhoneInput',

  props: {
    autofocus: {
      type: Boolean,
      default: false,
      required: false
    },
    asterisk: {
      type: Boolean,
      default: false,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    errors: {
      type: Array,
      default: () => [],
      required: false
    },
    hint: {
      type: String,
      default: '',
      required: false
    },
    id: {
      type: String,
      default: 'phone-input',
      required: false
    },
    label: {
      type: String,
      default: 'Phone number',
      required: false
    },
    placeholder: {
      type: String,
      default: '',
      required: false
    },
    required: {
      type: Boolean,
      default: false,
      required: false
    },
    value: {
      type: String,
      default: '',
      required: false
    },
    error: {
      type: Boolean,
      required: false,
      default: false
    },
    watchPhoneNumber: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      pn: '',
      localErrors: [],
      allowedKeys: ['Backspace', 'Tab', 'Control', 'Meta', 'ArrowLeft', 'ArrowRight', 'Left', 'Right'],
      copyEnabled: false
    }
  },

  computed: {
    computedErrors () {
      return this.localErrors.concat(this.errors)
    }
  },

  watch: {
    pn (val, oldVal) {
      let num = val !== null ? val.replace(/\D/g, '') : ''
      if (num.length > 6) {
        num = `(${num.slice(0, 3)}) ${num.slice(3, 6)}-${num.slice(6)}`
      } else if (num.length > 3) {
        num = `(${num.slice(0, 3)}) ${num.slice(3)}`
      }
      this.pn = num
      this.emitInputDebounced(num)
    },
    value (val, oldVal) {
      if (this.watchPhoneNumber) this.pn = val
    }
  },

  created () {
    this.pn = this.value
  },

  methods: {
    emitInputDebounced: _.debounce(function (val) {
      this.$emit('input', val)
    }, 100),
    preventChar (event) {
      if (event.key === 'Control' || event.key === 'Meta') {
        this.copyEnabled = true
      }

      if (!'0123456789'.includes(event.key) &&
          !this.allowedKeys.includes(event.key) &&
          !('acvx'.includes(event.key) && this.copyEnabled)) {
        event.preventDefault()
      }
    },
    disableCopy (event) {
      if (event.key === 'Control' || event.key === 'Meta') {
        this.copyEnabled = false
      }
    },
    validatePhoneNumber () {
      if (this.pn.length && this.pn.length < 14) {
        this.localErrors = [this.$store.borrowerWebContent.splashPhoneInputError]
      } else {
        this.localErrors = []
      }
    },
    clearErrors () {
      if (this.pn.length === 14) {
        this.localErrors = []
      }
    },
    parsePaste (event) {
      const clipboardData = event.clipboardData || window.clipboardData
      const pastedData = clipboardData.getData('Text').replace(/[^0-9.]/g, '').substring(0, 10)
      event.preventDefault()
      const tempNum = this.pn + pastedData
      this.pn = tempNum.substr(0, 14)
    }
  }
}
</script>
<style scoped>
.asterisk:after {
  color: red;
  content: " *";
}
</style>
