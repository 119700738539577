<template>
  <v-dialog
    v-model="dialog"
    max-width="605"
  >
    <v-card>
      <v-layout
        row
        wrap
      >
        <v-flex
          xs12
          class="text-xs-right"
        >
          <v-btn
            flat
            icon
            @click="dialog = false;"
          >
            <v-icon
              style="padding: 8px; font-size: 22px; color: black;"
            >
              {{ $vuetify.icons['sn-icon-exit'] }}
            </v-icon>
          </v-btn>
        </v-flex>

        <v-flex
          xs12
          style="margin-top: 66px;"
          class="text-xs-center"
        >
          <div class="sn-display-3">
            We suggest a switch.
          </div>
        </v-flex>

        <v-flex
          xs8
          offset-xs2
          style="margin-top: 49px;"
          class="text-xs-center"
        >
          <div class="sn-headline">
            Microsoft has announced that older versions of Internet Explorer will no longer be supported.
          </div>
        </v-flex>

        <v-flex
          xs8
          offset-xs2
          style="margin-top: 22px;"
          class="text-xs-center"
        >
          <div class="sn-headline">
            Click <a
              href="https://www.microsoft.com/en-us/microsoft-365/windows/end-of-ie-support"
              target="_blank"
              style="color: black;"
            >HERE</a> to learn more.
          </div>
        </v-flex>

        <v-flex
          xs12
          style="margin-top: 61px;"
          class="text-xs-center"
        >
          <div class="sn-subhead">
            We recommend one of these browsers!
          </div>
        </v-flex>

        <v-flex
          xs12
          style="margin-top: 12px; margin-bottom: 49px;"
          class="text-xs-center"
        >
          <a
            href="https://www.google.com/chrome/"
            target="_blank"
            style="display: inline-block; text-decoration: none;"
          >
            <img
              style="padding: 0 10px;"
              :src="chrome"
              alt="Google Chrome"
            >
            <figcaption
              class="sn-caption-2"
              style="color: black;"
            >Chrome</figcaption>
          </a>
          <a
            href="https://www.mozilla.org/en-US/firefox/new/"
            target="_blank"
            style="display: inline-block; text-decoration: none;"
          >
            <img
              style="padding: 0 10px;"
              :src="firefox"
              alt="Mozilla Firefox"
            >
            <figcaption
              class="sn-caption-2"
              style="color: black;"
            >Firefox</figcaption>
          </a>
          <a
            href="https://support.apple.com/downloads/safari"
            target="_blank"
            style="display: inline-block; text-decoration: none;"
          >
            <img
              style="padding: 0 10px;"
              :src="safari"
              alt="Apple Safari"
            >
            <figcaption
              class="sn-caption-2"
              style="color: black;"
            >Safari</figcaption>
          </a>
          <a
            href="https://www.microsoft.com/en-us/edge"
            target="_blank"
            style="display: inline-block; text-decoration: none;"
          >
            <img
              style="padding: 0 10px;"
              :src="edge"
              alt="Microsoft Edge"
            >
            <figcaption
              class="sn-caption-2"
              style="color: black;"
            >Edge</figcaption>
          </a>
        </v-flex>
      </v-layout>
    </v-card>
  </v-dialog>
</template>

<script>
import edge from '~/assets/images/edge.png'
import chrome from '~/assets/images/chrome.png'
import firefox from '~/assets/images/firefox.png'
import safari from '~/assets/images/safari.png'
export default {
  name: 'BrowserDeprecationWarning',
  data () {
    return {
      dialog: false,
      edge: edge,
      chrome: chrome,
      firefox: firefox,
      safari: safari
    }
  },
  created () {
    const date = localStorage.getItem('ieWarnedAt')
    if (window.document.documentMode && (!date || date !== new Date().toLocaleDateString())) {
      localStorage.setItem('ieWarnedAt', new Date().toLocaleDateString())
      this.dialog = true
    }
  }
}
</script>

<style scoped>

</style>
