<template>
  <v-layout
    row
    wrap
  >
    <v-flex
      sm9
      xs12
    >
      <v-layout
        row
        wrap
      >
        <v-flex
          v-if="$vuetify.breakpoint.smAndDown"
          xs12
          class="text-xs-center"
        >
          <v-img
            v-if="loanDoc && loanDoc.image_url"
            :src="loanDoc.image_url"
          />
        </v-flex>
        <v-flex
          xs12
          order-sm3
        >
          <SnuiButton
            :bold="false"
            :outline-size="1"
            :condensed-font="true"
            class="mx-0"
            button-text="Done"
            background-color="white"
            text-color="black"
            width="100%;"
            font-size="20px;"
            height="52px;"
            @click="$emit('navigation', 'close')"
          />
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>

import gql from 'graphql-tag'
import SnuiButton from '~/components/borrowerApp/SNUI/SnuiButton.vue'

const LoanDocQuery = gql`
  query document($guid: ID!) {
    document(guid: $guid) {
      id
      image_url
    }
  }
`

export default {
  name: 'ApiUploadedDocViewer',
  components: {
    SnuiButton
  },
  data () {
    return {
      loanDoc: null
    }
  },
  apollo: {
    document: {
      query: LoanDocQuery,
      variables () {
        return {
          guid: this.docGuid()
        }
      },
      update (data) {
        this.hideLoader()
        this.loanDoc = data.document
      },
      error (err) {
        this.showError('There was a problem fetching the document' + err)
      }
    }
  },
  methods: {
    docGuid () {
      const parts = this.$route.params.taskGuid.split('other_')
      return parts[parts.length - 1]
    },
    showError (message) {
      this.$emit('hideLoader')
      this.$snackbard.error({ text: message })
    },
    hideLoader () {
      this.$emit('hideLoader')
    }
  }
}
</script>

<style scoped>

</style>
