<template>
  <v-layout
    row
    wrap
  >
    <div
      v-if="!this.$vuetify.breakpoint.smAndDown"
      style="display: inline-block; margin-right: 16px; margin-left: auto; vertical-align: top; "
    >
      <v-btn
        v-if="!$vuetify.breakpoint.smAndDown"
        flat
        style="margin: 0px; "
        icon
        color="black"
        @click="$emit('navigation', 'HI_dashboard', 'backward')"
      >
        <v-icon>{{ $vuetify.icons['sn-icon-chevron-left-small'] }}</v-icon>
      </v-btn>
    </div>
    <div
      :class="getStyle()"
    >
      <div :class="getClass()">
        <div
          style="max-width: 432px; display: inline-block;"
        >
          <section v-if="(validAddress && !editing && (addressLineOne.length && addressLineTwo.length)) || informationIsCorrectChecked">
            <h4 class="sn-font-weight-extra-light sn-title-1 sn-font-condensed mb-18px">
              Does this address look right?
            </h4>
            <v-layout
              justify-start
              align-center
              class="mb-20px"
              @click="editing = true; informationIsCorrectChecked = false"
            >
              <v-flex
                xs1
              >
                <v-icon>
                  {{ $vuetify.icons['sn-icon-pencil'] }}
                </v-icon>
              </v-flex>
              <v-flex>
                <h4 class="sn-callout sn-font-weight-light">
                  {{ addressLineOne }}
                </h4>
                <h4 class="sn-callout sn-font-weight-light">
                  {{ addressLineTwo }}
                </h4>
              </v-flex>
            </v-layout>
          </section>
          <section v-else>
            <h4 class="sn-font-weight-extra-light sn-title-1 sn-font-condensed mb-18px">
              What address should we use?
            </h4>
            <v-layout
              row
              wrap
            >
              <v-flex xs4>
                <v-text-field
                  v-model="suggestedAddress.bestGuessStreetNumber"
                  label="Street Number"
                  @change="updateSuggestedAddress"
                />
              </v-flex>
              <v-flex
                class="pl-1"
                xs8
              >
                <v-text-field
                  v-model="suggestedAddress.bestGuessStreetName"
                  label="Street Name"
                  @change="updateSuggestedAddress"
                />
              </v-flex>
              <v-flex xs8>
                <v-text-field
                  v-model="suggestedAddress.city"
                  class="mr-8px"
                  label="City"
                  @change="updateSuggestedAddress"
                />
              </v-flex>
              <v-flex xs4>
                <v-select
                  v-model="suggestedAddress.state"
                  class="ml-8px"
                  xs4
                  :items="states"
                  label="State"
                  @change="updateSuggestedAddress"
                />
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="suggestedAddress.zip"
                  label="Zip Code"
                  @change="updateSuggestedAddress"
                />
              </v-flex>
            </v-layout>
          </section>
          <section>
            <h4 class="sn-font-weight-extra-light sn-title-1 sn-font-condensed mb-18px">
              Add a little more info to get your quotes
            </h4>
            <v-layout
              justify-start
              align-center
              row
              wrap
            >
              <v-flex xs6>
                <v-text-field
                  v-model="firstName"
                  label="First Name"
                />
              </v-flex>
              <v-flex
                xs6
                class="pl-1"
              >
                <v-text-field
                  v-model="lastName"
                  label="Last Name"
                />
              </v-flex>
              <v-flex
                xs6
                style="padding-right: 3px;"
              >
                <snDatePicker
                  v-model="dob"
                  label="Date of Birth"
                  icon=""
                  :rules="(v) => !!v || 'Date of Birth is required.'"
                  required
                  style="max-height: 68px;"
                />
              </v-flex>
              <v-flex xs6>
                <snPhoneInput
                  v-model="phoneNumber"
                  label="Phone Number"
                  :watch-phone-number="true"
                  hint=""
                />
              </v-flex>
              <v-flex
                xs12
                class="pl-1"
              >
                <v-text-field
                  v-model="email"
                  label="Email"
                />
              </v-flex>
              <v-flex xs6>
                <v-select
                  v-model="insuranceType"
                  :items="insuranceTypeOptions"
                  label="Insurance Type"
                />
              </v-flex>
            </v-layout>
          </section>
          <section>
            <v-layout class="mb-20px">
              <v-flex
                xs12
              >
                <v-divider />
              </v-flex>
            </v-layout>
            <v-layout
              justify-start
              align-center
              class="mb-16px"
            >
              <v-flex
                xs1
              >
                <input
                  v-model="informationIsCorrectChecked"
                  type="checkbox"
                  class="sn-checkbox pa-0 ma-0"
                >
              </v-flex>
              <v-flex>
                <h5 class="sn-subhead sn-font-weight-regular">
                  This information is correct
                </h5>
              </v-flex>
            </v-layout>
            <SnuiButton
              :bold="false"
              :background-color="canGetQuotes ? 'black' : '#BFBFBF'"
              :condensed-font="true"
              class="mx-0 mb-4px"
              button-text="Get Quotes"
              width="100%;"
              height="52px;"
              @click="getQuote"
            />
            <h6 class="sn-caption-1 sn-font-weight-regular">
              By clicking "Get Quotes" you agree to provide third party insurance providers with your personal information, including but not limited to name, date of birth, email, and the above address for the purpose of finding and comparing home insurance quotes, and you agree to our Terms & Conditions. Information you provide to others is subject to their privacy policies and terms of use.
            </h6>
          </section>
        </div>
      </div>
    </div>
  </v-layout>
</template>

<script>
import SnuiButton from '~/components/borrowerApp/SNUI/SnuiButton.vue'
import snDatePicker from '~/components/common/snDatePicker'
import snPhoneInput from '~/components/common/snPhoneInput'
import { borrowerHomeInsuranceTaskQuery, homeInsuranceSuggestedAddressQuery, loanBorrowerQuery } from './queries.gql'
import { createInsuranceQuotesForTask, updateLocalAddressMutation, updateSuggestedAddressMutation } from './mutations.gql'
import AppCommunicationMixin, {
  // OPEN_CUSTOM_LINK,
  LOG
} from '~/components/common/mixins/AppCommunicationMixin'
import _ from 'lodash'

export default {
  components: {
    SnuiButton,
    snDatePicker,
    snPhoneInput
  },
  mixins: [AppCommunicationMixin],
  apollo: {
    suggestedAddress: {
      query: homeInsuranceSuggestedAddressQuery,
      update (data) {
        if (data?.homeInsurance?.suggestedAddress?.bestGuessIsCondo) {
          this.insuranceType = 'condo'
        } else if (data?.homeInsurance?.suggestedAddress?.bestGuessIsManufactured) {
          this.insuranceType = 'mobile'
        }
        return _.get(data, 'homeInsurance.suggestedAddress', {
          bestGuessStreetNumber: '',
          bestGuessStreetName: '',
          city: '',
          state: '',
          zip: '',
          propertyType: '',
          bestGuessIsCondo: '',
          bestGuessIsManufactured: ''
        })
      }
    },
    evidenceOfInsuranceTask: {
      query: borrowerHomeInsuranceTaskQuery,
      fetchPolicy: 'network-only',
      variables () {
        return {
          guid: this.$route.params.taskGuid
        }
      },
      update (data) {
        return data.evidenceOfInsuranceTask
      },
      result ({ data }) {
        if (!this.validAddress) {
          this.editing = true
        }
      }
    },
    borrower: {
      query: loanBorrowerQuery,
      variables () {
        return {
          loanGuid: this.evidenceOfInsuranceTask?.loanGuid
        }
      },
      update (data) {
        if (data?.loan?.borrower) {
          const borrower = data.loan.borrower
          this.firstName = borrower.first_name
          this.lastName = borrower.last_name
          this.email = borrower.email
          this.phoneNumber = borrower.main_phone
          this.dob = borrower.dob
          this.thirdPartyConsent = borrower.app_user.app_user_setting.third_party_consent
        }
        this.$emit('hideLoader')
        return data?.loan?.borrower
      },
      skip () {
        return !this.evidenceOfInsuranceTask?.loanGuid
      }

    }
  },
  data () {
    return {
      editing: false,
      firstName: null,
      lastName: null,
      email: null,
      dob: '',
      informationIsCorrectChecked: false,
      suggestedAddress: {
        bestGuessStreetNumber: '',
        bestGuessStreetName: '',
        city: '',
        state: '',
        zip: '',
        propertyType: ''
      },
      states: ['AK', 'AL', 'AR', 'AZ', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA', 'HI', 'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'ME', 'MI', 'MN', 'MO', 'MS', 'MT', 'NC', 'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 'NY', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VA', 'VT', 'WA', 'WI', 'WV', 'WY'],
      hippoUrl: '',
      squareFt: null,
      singleFamily: false,
      primaryHome: false,
      ownsAMortgage: false,
      insuranceType: 'homeowners',
      insuranceTypeOptions: [
        {
          text: 'Home',
          value: 'homeowners'
        },
        {
          text: 'Condo',
          value: 'condo'
        },
        {
          text: 'Mobile/Manufactured',
          value: 'mobile'
        }
      ],
      dialog: false,
      phoneNumber: '',
      dateErrors: []
    }
  },
  computed: {
    addressLineOne () {
      let text = ''
      if (this.suggestedAddress.bestGuessStreetNumber) text += this.suggestedAddress.bestGuessStreetNumber
      if (this.suggestedAddress.bestGuessStreetName) text += ` ${this.suggestedAddress.bestGuessStreetName}`
      if (this.addressLineTwo.length > 0 && text.length > 0) text += ','
      return text
    },
    addressLineTwo () {
      let text = ''
      if (this.suggestedAddress.city) text += this.suggestedAddress.city
      if (this.suggestedAddress.city && this.suggestedAddress.state) text += ', '
      if (this.suggestedAddress.state) text += `${this.suggestedAddress.state}`
      if (this.suggestedAddress.zip) text += ` ${this.suggestedAddress.zip}`
      return text
    },
    validAddress () {
      const str = `${this.suggestedAddress.bestGuessStreetNumber}${this.suggestedAddress.bestGuessStreetName}${this.suggestedAddress.city}${this.suggestedAddress.state}${this.suggestedAddress.zip}`
      return (str && str.length !== 0)
    },
    canGetQuotes () {
      return (this.informationIsCorrectChecked &&
        this.firstName && this.lastName && this.email && this.dob && !this.dateErrors.length &&
        this.suggestedAddress.bestGuessStreetNumber && this.suggestedAddress.bestGuessStreetName && this.suggestedAddress.city && this.suggestedAddress.state && this.suggestedAddress.zip
      )
    }
  },

  methods: {
    getClass () {
      return {
        'ma-3': this.$vuetify.breakpoint.smAndDown,
        'mr-136px ml-0': !this.$vuetify.breakpoint.smAndDown
      }
    },
    getStyle () {
      return {
        'margin-auto': this.$vuetify.breakpoint.smAndDown
      }
    },
    updateSuggestedAddress: _.throttle(function (suggestedAddress) {
      this.$apollo.mutate({
        mutation: updateSuggestedAddressMutation,
        variables: {
          taskGuid: this.$route.params.taskGuid,
          ...this.suggestedAddress
        }
      }).then(({ data }) => {
        this.$apollo.mutate({
          mutation: updateLocalAddressMutation,
          variables: {
            ..._.get(data, 'updateSuggestedAddress.suggestedAddress', {})
          }
        })
      })
    }, 100),
    getQuoteVariables () {
      return {
        streetNumber: this.suggestedAddress.bestGuessStreetNumber,
        street: this.suggestedAddress.bestGuessStreetName,
        city: this.suggestedAddress.city,
        state: this.suggestedAddress.state,
        zip: this.suggestedAddress.zip,
        firstName: this.firstName,
        lastName: this.lastName,
        dateOfBirth: this.dob || '',
        email: this.email,
        phoneNumber: this.phoneNumber || '',
        taskGuid: this.$route.params.taskGuid,
        insuranceType: this.insuranceType,
        fromMobile: this.$store.inMobileApp || this.$vuetify.breakpoint.smAndDown
      }
    },
    getQuote () {
      if (!this.canGetQuotes) return

      this.$emit('showLoader', [{ text: 'Searching for the best deals', classes: 'sn-title-1 sn-font-weight-extra-light sn-font-condensed' }, { text: 'This may take up to 60 seconds', classes: 'sn-callout sn-font-weight-regular' }])
      this.$apollo.mutate({
        fetchPolicy: 'no-cache',
        mutation: createInsuranceQuotesForTask,
        variables: this.getQuoteVariables()
      }).then((result) => {
        this.$emit('navigation', 'HI_selectPolicy', 'forward')
      }).catch((error) => {
        this.$emit('hideLoader')
        if (error.message) {
          this.postMessageToApp(LOG, error.message)
        }
        this.$emit('navigation', 'HI_error', 'backward')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.ml-8px {
  margin-left: 8px;
}
.mr-8px {
  margin-right: 8px;
}
.mb-4px {
  margin-bottom: 4px;
}
.mb-16px {
  margin-bottom: 16px;
}
.mb-18px {
  margin-bottom: 18px;
}
.mb-20px {
  margin-bottom: 20px;
}
</style>

<style lang="scss" scoped>
.ml-8px {
  margin-left: 8px;
}
.mr-8px {
  margin-right: 8px;
}
.mb-4px {
  margin-bottom: 4px;
}
.mb-16px {
  margin-bottom: 16px;
}
.mb-18px {
  margin-bottom: 18px;
}
.mb-20px {
  margin-bottom: 20px;
}

.mr-136px {
  margin-right: 136px;
}
.margin-auto {
  margin: auto;
}

</style>
