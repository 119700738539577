<template>
  <div :class="getClass()">
    <section>
      <h4 class="sn-font-weight-semi-bold sn-title-3 mb-2px">
        Proof of Home Insurance
      </h4>
      <h5 class="sn-font-weight-medium sn-subhead sn-text-primary--lighten-2 mb-12px">
        Provide policy info, or get free quotes
      </h5>
      <h5 class="sn-font-weight-regular sn-callout mb-20px">
        In order to close on your home, we need to ensure it's covered by a home insurance policy.
      </h5>
      <v-layout
        align-center
        justify-space-between
        row
        fill-height
        hoverable
        class="home-insurance-policy-info sn-background-primary--lighten-5 mb-30px"
        @click="$emit('navigation', 'HI_enterPolicy')"
      >
        <v-flex
          xs11
          sm10
        >
          <h5 class="sn-font-weight-medium sn-callout ml-16px">
            Home Insurance Policy Info
          </h5>
        </v-flex>
        <div
          class="mr-16px"
        >
          <v-icon
            v-if="$vuetify.breakpoint.smAndDown"
            v-text="'$vuetify.icons.sn-icon-chevron-right'"
          />
          <SnuiButton
            v-else
            :semi-bold="true"
            button-text="Fill Form"
            @click="fillFormTapped"
          />
        </div>
      </v-layout>
    </section>
    <v-layout class="mb-24px">
      <v-flex
        xs12
      >
        <v-divider />
      </v-flex>
    </v-layout>
    <v-layout
      row
      wrap
    >
      <v-flex xs12>
        <h3 class="sn-font-weight-extra-light sn-title-1 sn-font-condensed mb-12px">
          Don't have a policy or want to compare rates?
        </h3>
      </v-flex>
      <v-flex
        v-if="!$vuetify.breakpoint.smAndDown"
        sm3
        class="text-xs-center"
      >
        <HomeShield
          class="home-shield"
        />
      </v-flex>
      <v-flex
        sm9
        xs12
      >
        <v-layout
          row
          wrap
        >
          <v-flex
            xs12
            order-sm3
          >
            <SnuiButton
              :bold="false"
              :outline-size="1"
              :condensed-font="true"
              class="mx-0"
              button-text="Compare Rates Online"
              background-color="white"
              text-color="black"
              width="100%;"
              font-size="20px;"
              height="52px;"
              @click="compareRatesClicked()"
            />
          </v-flex>

          <v-flex
            v-if="$vuetify.breakpoint.smAndDown"
            xs12
            class="text-xs-center"
          >
            <HomeShield
              class="mb-20px responsive"
            />
          </v-flex>

          <v-flex>
            <h4 class="sn-callout sn-font-weight-regular mb-8px">
              Use our online rate finder tool to <span class="sn-font-weight-semi-bold">compare free quotes</span> from multiple vendors and <span class="sn-font-weight-semi-bold">discover potential savings</span>.
            </h4>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import SnuiButton from '~/components/borrowerApp/SNUI/SnuiButton.vue'
import { borrowerHomeInsuranceTaskQuery } from './queries.gql'
import { updateLocalAddressMutation } from './mutations.gql'
import HomeShield from '~/assets/images/HomeShield.snvg'
import _ from 'lodash'

export default {
  components: {
    SnuiButton,
    HomeShield
  },

  apollo: {
    evidenceOfInsuranceTask: {
      query: borrowerHomeInsuranceTaskQuery,
      variables () {
        return {
          guid: this.$route.params.taskGuid
        }
      },
      fetchPolicy: 'network-only',
      update (data) {
        this.$emit('hideLoader')
        return data.evidenceOfInsuranceTask
      },
      result ({ data }) {
        this.$apollo.mutate({
          mutation: updateLocalAddressMutation,
          variables: {
            ..._.get(data, 'evidenceOfInsuranceTask.suggestedAddress', {})
          }
        })
      }
    }
  },

  methods: {
    fillFormTapped () {
      this.$emit('navigation', 'HI_enterPolicy')
    },
    getClass () {
      return {
        'mt-3': this.$vuetify.breakpoint.smAndDown,
        'mx-3': this.$vuetify.breakpoint.smAndDown
      }
    },
    compareRatesClicked () {
      this.$emit('showLoader')
      if (this.evidenceOfInsuranceTask?.insuranceQuotes?.length) {
        this.$emit('navigation', 'HI_selectPolicy')
      } else {
        this.$emit('navigation', 'HI_verifyInformation')
      }
    }
  }

}
</script>

<style lang="scss" scoped>
.preferred-provider-link {
  color: black;
  text-decoration: none;
}
.home-insurance-policy-info {
  height: 80px;
  border-radius: 4px;
}
.home-shield-padding {
  @media (max-width: 959px) {
    padding-left: 72px;
    padding-right: 72px;
  }
}
.home-shield {
  max-height: 120px;
}
.responsive {
  width: 70%;
  height: auto;
}
.mr-16px {
  margin-right: 16px;
}
.mr-72px {
  margin-right: 72px;
}
.mb-2px {
  margin-bottom: 2px;
}
.mb-4px {
  margin-bottom: 4px;
}
.mb-8px {
  margin-bottom: 8px;
}
.mb-12px {
  margin-bottom: 12px;
}
.mb-20px {
  margin-bottom: 20px;
}
.mb-24px {
  margin-bottom: 24px;
}
.mb-30px {
  margin-bottom: 30px;
}
.ml-16px {
  margin-left: 16px;
}
.ml-72px {
  margin-left: 72px;
}

</style>
