<template>
  <div id="date-picker">
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      full-width
      max-width="290px"
      min-width="290px"
      style="display: block"
    >
      <template v-slot:activator="{on}">
        <v-text-field
          v-if="hideIcon"
          slot="activator"
          v-model="dateFormatted"
          :rules="required ? validDateRules : []"
          :label="label"
          :disabled="disabled"
          v-on="on"
          @blur="parseDate"
        />
        <v-text-field
          v-else
          slot="activator"
          v-model="dateFormatted"
          :rules="required ? validDateRules : []"
          :label="label"
          :disabled="disabled"
          :prepend-icon="icon"
          v-on="on"
          @blur="parseDate"
        />
      </template>

      <v-date-picker
        v-model="internalDate"
        :type="type"
        :color="passedColor"
        :min="afterToday ? new Date().toISOString().slice(0,10) : null"
        @input="setDate"
      >
        <v-spacer />
        <v-btn
          text
          color="primary"
          @click="menu = false"
        >
          Cancel
        </v-btn>
        <v-btn
          text
          color="primary"
          @click="$refs.menu.save(internalDate)"
        >
          Save
        </v-btn>
      </v-date-picker>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'DateInput',

  props: {
    value: { type: String, required: true },
    label: { type: String, default: 'Date' },
    hideIcon: { type: Boolean, default: false },
    icon: { type: String, default: 'event' },
    type: { type: String, default: 'date' },
    required: { type: Boolean, default: false },
    passedColor: { type: String, required: false, default: null },
    format: { type: String, default: '' },
    afterToday: { type: Boolean, default: false },
    uppercase: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false }
  },
  data () {
    return {
      menu: false,
      internalDate: null,
      dateFormatted: null,
      validDateRules: [
        v => !!v || 'This field is required.'
      ]
    }
  },

  watch: {
    value (newVal, oldVal) {
      this.internalDate = this.standardizeDate(newVal)
      this.dateFormatted = this.formatDate(this.internalDate)
    }
  },

  created () {
    this.internalDate = this.standardizeDate(this.value)
    this.dateFormatted = this.formatDate(this.internalDate)
  },

  methods: {
    setDate (date) {
      this.dateFormatted = this.formatDate(date)
      this.$emit('input', this.internalDate)
    },

    formatDate (date) {
      if (!date) {
        return ''
      }

      if (this.type === 'date') {
        if (this.format !== '') {
          if (this.uppercase) { return this.$moment(date).format(this.format).toUpperCase() } else { return this.$moment(date).format(this.format) }
        } else {
          const [year, month, day] = date.split('-')
          return `${month}/${day}/${year}`
        }
      } else if (this.type === 'month') {
        return this.$moment(date).format('MMMM YYYY')
      }
    },

    standardizeDate (dateStr) {
      if (!dateStr) {
        return ''
      }

      return this.$moment(dateStr).format('YYYY-MM-DD')
    },

    parseDate () {
      if (!this.dateFormatted) {
        this.internalDate = ''
      }

      if (this.type === 'date' && this.dateFormatted) {
        const [month, day, year] = this.dateFormatted.split('/')
        if (!!month && !!day) { this.internalDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}` }
      }

      this.$emit('input', this.internalDate)
    }
  }

}
</script>
