import Cookie from 'js-cookie'
export default {
  name: 'AutoLogoutMixin',
  methods: {
    sn_AutoLogoutMixin_isSafari () {
      return navigator.vendor.toLowerCase().includes('apple')
    },
    sn_AutoLogoutMixin_isIE () {
      return !!window.MSInputMethodContext && !!document.documentMode
    },
    sn_AutoLogoutMixin_setUpAutoLogout (logoutPath) {
      localStorage.setItem('sn_random', Cookie.get('sn_random'))
      window.addEventListener('storage', (e) => {
        if (e.key === 'sn_random' && e.newValue !== 'undefined' && e.oldValue !== e.newValue) {
          if (this.sn_AutoLogoutMixin_isIE() || this.sn_AutoLogoutMixin_isSafari()) {
            if (window.location.href !== e.url) window.location.href = logoutPath()
          } else {
            window.location.href = logoutPath()
          }
        }
      })
    }
  }
}
