<template>
  <div>
    <div
      class="pa-0"
      style="margin-right: 16px; margin-left: 16px;"
    >
      <div>
        <div
          v-if="!loView"
          class="instructions sn-callout"
        >
          {{ instructions }}
        </div>
        <v-layout
          row
          wrap
          style="margin-top: 32px;"
        >
          <v-flex
            lg12
            md12
            sm12
            xs12
            style="margin-bottom: 0; margin-left: -20px;"
            class="mr-2"
          >
            <div
              class="sn-title-3 amount-due"
              :class="$vuetify.breakpoint.mdAndDown ? 'ml-auto mr-auto align-center' : 'ml-3 align-center'"
            >
              Amount Due
            </div>
            <div
              ref="currentBalance"
              class="current-balance"
              data-cy="mobile-amount-due"
              :class="`${$vuetify.breakpoint.mdAndDown ? 'ml-auto mr-auto align-center' : 'ml-3 align-center'}${$vuetify.breakpoint.lg && $vuetify.breakpoint.width < 1422 ? ' current-balance-sm' : ''}`"
              :style="currentBalanceFontSize"
            >
              {{ currentBalance }}
            </div>
          </v-flex>
          <v-spacer />
          <v-flex
            lg12
            md12
            sm12
            xs12
          >
            <div
              v-if="creditAllowed && achAllowed"
              class="select-payment"
            >
              Select Payment Method
            </div>
            <v-layout
              row
              wrap
            >
              <v-flex
                v-if="creditAllowed && achAllowed"
                xs6
              >
                <v-card
                  style="margin-right: 4px;"
                  data-cy="mobile-credit-card"
                  :style="shouldHighlightCredit ? `background-color: ${primaryColorWithOpacity}; border: solid 1px ${primaryColor}` : ''"
                >
                  <div
                    class="ma-auto payment-type"
                    :class="creditAllowed && achAllowed ? 'pointer-cursor' : ''"
                    @click="enableCredit"
                  >
                    <div>
                      <v-icon
                        class="payment-icon"
                        :style="shouldHighlightCredit ? `color: ${primaryColor}` : ''"
                      >
                        {{ creditCardIcon }}
                      </v-icon>
                    </div>
                    <div class="payment-type-text">
                      Credit Card
                    </div>
                  </div>
                </v-card>
              </v-flex>
              <v-flex
                v-if="achAllowed && creditAllowed"
                xs6
              >
                <v-card
                  style="margin-left: 4px;"
                  data-cy="mobile-bank-account"
                  :style="shouldHighlightBank ? `background-color: ${primaryColorWithOpacity}; border: solid 1px ${primaryColor}` : ''"
                >
                  <div
                    class="ma-auto payment-type align-center"
                    :class="achAllowed && creditAllowed ? 'pointer-cursor' : ''"
                    @click="enableBank"
                  >
                    <div>
                      <v-icon
                        class="payment-icon"
                        :style="shouldHighlightBank ? `color: ${primaryColor}` : ''"
                      >
                        {{ bankIcon }}
                      </v-icon>
                    </div>
                    <div class="payment-type-text">
                      Bank Account
                    </div>
                  </div>
                </v-card>
              </v-flex>
              <v-flex xs12>
                <v-form v-model="formValid">
                  <div :style="achAllowed && creditAllowed ? 'margin-top: 20px;' : ''">
                    <v-layout
                      v-if="creditTypeSelected || (creditAllowed && !achAllowed)"
                      row
                      wrap
                    >
                      <v-flex xs12>
                        <div>
                          <v-text-field
                            v-model="cardNumber"
                            v-mask="cardMask"
                            label="Card Number"
                            data-cy="mobile-card-number"
                            type="text"
                            :rules="focusedField === 'cardNumber' ? [] : [creditRules.required, creditRules.cardLength, creditRules.valid]"
                            @focus="focusedField = 'cardNumber'"
                            @blur="focusedField = null"
                          />
                        </div>
                      </v-flex>
                      <v-flex xs6>
                        <div style="padding-right: 8px;">
                          <v-text-field
                            v-model="expirationDate"
                            v-mask="'##/####'"
                            label="Expiration Date"
                            data-cy="mobile-expiration-date"
                            :rules="focusedField === 'expirationDate' ? [] : [creditRules.required, creditRules.expirationDate]"
                            hint="mm/yy"
                            @focus="focusedField = 'expirationDate'"
                            @blur="focusedField = null"
                          />
                        </div>
                      </v-flex>
                      <v-flex
                        xs6
                      >
                        <div style="padding-left: 8px;">
                          <v-text-field
                            v-model="securityCode"
                            v-mask="'####'"
                            label="Security Code"
                            data-cy="mobile-security-code"
                            :rules="focusedField === 'securityCode' ? [] : [creditRules.required, creditRules.securityCodeLength]"
                            type="text"
                            @focus="focusedField = 'securityCode'"
                            @blur="focusedField = null"
                          />
                        </div>
                      </v-flex>
                      <v-flex xs12>
                        <div class="billing-address">
                          Billing Address
                        </div>
                      </v-flex>
                      <v-flex xs8>
                        <div :style="{ marginBottom: !loView ? '85px' : 0 }">
                          <v-text-field
                            v-model="streetAddress"
                            label="Street"
                            data-cy="mobile-billing-street"
                            style="padding-right: 8px;"
                            :rules="focusedField === 'streetAddress' ? [] : [creditRules.required]"
                            @focus="focusedField = 'streetAddress'"
                            @blur="focusedField = null"
                          />
                        </div>
                      </v-flex>
                      <v-flex xs4>
                        <div :style="{ marginBottom: !loView ? '85px' : 0 }">
                          <v-text-field
                            v-model="zipCode"
                            label="Zip Code"
                            data-cy="mobile-billing-zip"
                            style="padding-left: 8px;"
                            :rules="focusedField === 'zipCode' ? [] : [creditRules.zipCode]"
                            @focus="focusedField = 'zipCode'"
                            @blur="focusedField = null"
                          />
                        </div>
                      </v-flex>
                      <v-flex xs12>
                        <div class="verification-language">
                          {{ verificationLanguage }}
                        </div>
                      </v-flex>
                    </v-layout>
                    <v-layout
                      v-else-if="bankTypeSelected || (achAllowed && !creditAllowed)"
                      row
                      wrap
                    >
                      <v-flex xs12>
                        <div>
                          <div class="nexus-pay-label">
                            Account Type:
                          </div>
                          <v-radio-group
                            v-model="accountType"
                            style="margin-top: 0;"
                            :rules="focusedField === 'accountType' ? [] : [bankRules.required]"
                            @focus="focusedField = 'accountType'"
                            @blur="focusedField = null"
                          >
                            <v-radio
                              label="Checking Account"
                              data-cy="mobile-checking-account"
                              value="Checking Account"
                              style="margin-bottom: 18px"
                            />
                            <v-radio
                              label="Savings Account"
                              data-cy="mobile-savings-account"
                              value="Savings Account"
                            />
                          </v-radio-group>
                        </div>
                      </v-flex>
                      <v-flex xs12>
                        <div>
                          <v-text-field
                            v-model="accountHolderName"
                            label="Account Holder Name"
                            data-cy="mobile-account-holder-name"
                            :rules="focusedField === 'accountHolderName' ? [] : [bankRules.required, bankRules.fullName]"
                            hint="Enter first and last name"
                            @focus="focusedField = 'accountHolderName'"
                            @blur="focusedField = null"
                          />
                        </div>
                      </v-flex>
                      <v-flex xs12>
                        <div>
                          <v-text-field
                            v-model="routingNumber"
                            v-mask="'#########'"
                            label="Routing Number"
                            data-cy="mobile-routing-number"
                            :rules="focusedField === 'routingNumber' ? [] : [bankRules.required]"
                            type="text"
                            @focus="focusedField = 'routingNumber'"
                            @blur="focusedField = null"
                          />
                        </div>
                      </v-flex>
                      <v-flex xs12>
                        <div :style="{ marginBottom: !loView ? '85px' : 0 }">
                          <v-text-field
                            v-model="accountNumber"
                            v-mask="'#################'"
                            label="Account Number"
                            :data-cy="'mobile-account-number'"
                            :rules="focusedField === 'accountNumber' ? [] : [bankRules.required]"
                            type="text"
                            @focus="focusedField = 'accountNumber'"
                            @blur="focusedField = null"
                          />
                        </div>
                      </v-flex>
                      <v-flex xs12>
                        <div class="verification-language">
                          {{ verificationLanguage }}
                        </div>
                      </v-flex>
                    </v-layout>
                  </div>
                </v-form>
              </v-flex>
              <v-flex
                v-if="errorMessage"
                xs12
              >
                <v-alert
                  outlined
                  color="#D44011"
                  text
                >
                  {{ errorMessage }}
                </v-alert>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </div>
      <div
        v-if="paymentSuccess"
        style="position: absolute; left: 0; top: 0; background-color: rgba(255, 255, 255, 0.95); width: 100%; height: 100%;"
      >
        <div style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); width: 100%;">
          <div style="text-align: center; margin: auto; position: relative">
            <check-with-pencil
              :style="pencilCSSProps"
            />
            <div
              class="thank-you"
              data-cy="mobile-thank-you"
            >
              Thank you!
            </div>
            <div class="processing-message">
              We're processing your payment.
            </div>
            <div class="processing-message">
              We'll send you a confirmation email when we finish processing
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!paymentSuccess"
      :style="!loView ? [styleObject, widthStyle] : styleObject"
    >
      <v-btn
        v-if="!loView"
        :disabled="!canSubmitPayment"
        block
        class="pay-button"
        data-cy="mobile-pay-button"
        style="width: 100% !important; color: #FFFFFF !important; background: #000000; padding: 0; height: 52px;"
        @click="initiateMobilePayment"
      >
        Pay {{ paymentAmount }}
      </v-btn>
    </div>
    <div
      v-else-if="!loView"
      :style="!loView ? [styleObject, widthStyle] : styleObject"
    >
      <v-btn
        block
        class="pay-button"
        style="width: 100% !important; color: #FFFFFF !important; background: #000000; padding: 0; height: 52px;"
        @click="finishAndClose"
      >
        Finish
      </v-btn>
    </div>
  </div>
</template>

<script>
import nexusPayMixin from '../../../common/mixins/nexusPayMixin'
export default {
  name: 'NexusPayMobileTask',
  mixins: [nexusPayMixin],
  data () {
    return {
      styleObject: {
        position: 'absolute',
        bottom: 0,
        padding: '16px'
      },
      widthStyle: {
        width: '100%'
      },
      currentBalanceFontSize: {
        fontSize: '48px'
      }
    }
  },
  watch: {
    currentBalance () {
      this.setCurrentBalanceFontSize()
    },
    paymentSuccess (newVal) {
      if (newVal) {
        this.$emit('hideLoader')
      }
    }
  },
  mounted () {
    this.setCurrentBalanceFontSize()
  },
  methods: {
    setCurrentBalanceFontSize () {
      const div = this.$refs.currentBalance
      div.style.fontSize = this.currentBalanceFontSize.fontSize
      let fontSize = parseInt(div.style.fontSize)
      while (div.scrollWidth > div.clientWidth) {
        fontSize -= 1
        div.style.fontSize = fontSize + 'px'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/css/nexusPay';
::v-deep .v-text-field input {
  margin-bottom: 0;
}

::v-deep .v-text-field label {
  top: -3px !important;
}

::v-deep .v-text-field__slot {
  max-height: 32px;
}

.charge-name {
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
