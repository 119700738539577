<template>
  <v-layout
    wrap
    row
    :class="{'justify-center': $vuetify.breakpoint.smAndDown}"
  >
    <v-flex
      :class="{'xs10 ': $vuetify.breakpoint.smAndDown}"
      md6
      class="mt-3"
    >
      <phone-input
        v-model="sn_shareBySmsMixin_sendToMobileNumber"
        :errors="sn_shareBySmsMixin_errors"
        label=""
        :placeholder="`${store.borrowerWebContent && store.borrowerWebContent.splashMobileNumber}`"
        :hint="`${store.borrowerWebContent && store.borrowerWebContent.splashPhoneHint}`"
      />
    </v-flex>
    <v-flex
      xs12
      md6
      class="mt-1"
      text-xs-center
    >
      <v-btn
        :class="{'pl-4 pr-4': $vuetify.breakpoint.mdAndUp, 'ml-0': $vuetify.breakpoint.smAndDown, 'btn--block': $vuetify.breakpoint.xs}"
        class="btn--xlarge primary"
        :aria-label="`${store.borrowerWebContent && store.borrowerWebContent.splashAppSendMsg}`"
        dark
        @click.native="sn_shareBySmsMixin_shareAppBySms(store.activationCode, sn_shareBySmsMixin_sendToMobileNumber, 'borrower_invitation')"
      >
        {{ store.borrowerWebContent && store.borrowerWebContent.splashAppSendMsg }}
      </v-btn>
    </v-flex>
    <v-flex

      :class="{'justify-center xs10  text-xs-center ':$vuetify.breakpoint.smAndDown }"

      class="mt-1 caption font-weight-light"
    >
      {{ store.borrowerWebContent && store.borrowerWebContent.splashLegalRestrictions }}
    </v-flex>
  </v-layout>
</template>

<script>
import snPhoneInput from '../common/snPhoneInput.vue'
import shareBySmsMixin from '../common/shareBySmsMixin.js'

export default {
  name: 'BorrowerShareAppBySms',
  components: {
    'phone-input': snPhoneInput
  },

  mixins: [shareBySmsMixin],

  data () {
    return {
      sn_shareBySmsMixin_errors: [],
      store: this.$store
    }
  }
}
</script>
