<template>
  <div>
    <nexus-pay-task
      v-if="nexusPayTaskQuery"
      :value="nexusPayTaskQuery"
      @navigation="navigationEvent"
      @display-error="showError"
      @showLoader="showLoader"
      @hideLoader="hideLoader"
      @hide-mobile-loader="hideLoader"
    />
  </div>
</template>

<script>
import nexusPayTask from './nexusPayMobileTask.vue'
import { borrowerNexusPayTaskQuery } from './queries.gql'

export default {
  name: 'NexusPay',
  components: {
    nexusPayTask
  },
  apollo: {
    nexusPayTaskQuery: {
      query: borrowerNexusPayTaskQuery,
      variables () {
        return {
          guid: this.$route.params.taskGuid
        }
      },
      fetchPolicy: 'network-only',
      update ({ nexusPayTask }) {
        this.$emit('hideLoader')
        return {
          taskDetail: nexusPayTask
        }
      }
    }
  },
  methods: {
    navigationEvent (event) {
      this.$emit('navigation', event)
    },
    showError (message) {
      this.$emit('hideLoader')
      this.$snackbard.error({ text: message })
    },
    showLoader (messages) {
      this.$emit('showLoader', messages)
    },
    hideLoader () {
      this.$emit('hideLoader')
    }
  }
}
</script>
