<template>
  <div>
    <v-divider
      v-if="divider"
      class="my-3"
    />
    <v-list-tile
      v-else
      class="sidenav-font"
      @click="clickAction"
    >
      <v-icon :style="isActive ? `color: ${primaryThemeColor};margin-right: 12px;` : 'color: #000000;margin-right: 12px;'">
        {{ $vuetify.icons[iconName] }}
      </v-icon>
      <span
        class="sidenav-font"
        :style="isActive ? 'font-weight: 600;' : ''"
      >
        {{ title }}
      </span>
    </v-list-tile>
  </div>
</template>

<script>
export default {
  props: {
    divider: {
      type: Boolean,
      required: false,
      default: false
    },
    title: {
      type: String,
      required: false,
      default: ''
    },
    icon: {
      type: String,
      required: false,
      default: ''
    },
    clickAction: {
      type: Function,
      required: false,
      default: () => {}
    },
    isActiveFunction: {
      type: Function,
      required: false,
      default: () => {}
    }
  },
  computed: {
    iconName () {
      if (this.icon.includes('sn-icon')) return this.icon
      return `sn-icon-${this.icon}`
    },
    isActive () {
      return this.isActiveFunction()
    },
    primaryThemeColor () {
      return this.$vuetify.theme.primary
    }
  }
}
</script>

<style scoped>
  .sidenav-font {
    font-size: 17px !important;
  }
  .sidenav-tile {
    width: 100%;
    padding-top: 17px !important;
    padding-bottom: 17px !important;
  }
</style>
<style>
  .v-application a {
    color: inherit !important;
  }
  .v-list__tile {
    padding: 0 8px !important;
  }
</style>
