<template>
  <div>
    <v-layout
      align-center
      justify-center
      column
      fill-height
    >
      <template v-if="validImage && logoSrc && logoSrc !== '' && logoSrc !== 'not loaded'">
        <img
          :src="logoSrc"
          :alt="logoAlt"
          :style="imageSize"
          @error="validImage = false"
        >
      </template>
      <template v-else-if="!validImage || logoSrc !== null">
        <v-icon x-large>
          domain
        </v-icon>
      </template>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: 'DefaultCompanyLogo',
  props: {
    logoSrc: {
      type: String,
      default: '',
      required: false
    },
    logoAlt: {
      type: String,
      default: 'Company Logo',
      required: false
    }
  },
  data () {
    return {
      validImage: true
    }
  },
  computed: {
    imageSize () {
      var style = 'padding-bottom:10px;'
      if (this.$vuetify.breakpoint.mdAndUp) {
        style += 'height:120px;'
      } else if (this.$vuetify.breakpoint.width > 370) {
        style += 'height:90px;'
      } else {
        style += 'height:60px;'
      }
      return style
    }
  }
}
</script>
