<template>
  <div class="full-height">
    <v-layout
      v-show="loading && component"
      column
      align-center
      justify-center
      fill-height
      class="loading-wrapper"
    >
      <v-flex shrink>
        <div
          id="loading-view"
          ref="loadingView"
        />
      </v-flex>

      <v-flex
        v-for="message in loadingMessages"
        :key="message.text"
        shrink
      >
        <span :class="message.classes">{{ message.text }}</span>
      </v-flex>
    </v-layout>
    <transition
      :name="transitionName"
      mode="out-in"
    >
      <component
        :is="component"
        v-if="component"
        :class="['router-wrapper', { hidden: loading }]"
        @hideLoader="hideLoader"
        @showLoader="showLoader"
        @navigation="navigationEvent"
      />
    </transition>
  </div>
</template>

<script>
import lottie from 'lottie-web'
import AppCommunicationMixin, {
  CLOSE
} from '~/components/common/mixins/AppCommunicationMixin'
import loadingJSON from '~/assets/lottieAnimations/loading.json'
import Dashboard from '../borrowerTasks/homeInsurance/dashboard.vue'
import enterPolicy from '../borrowerTasks/homeInsurance/enterPolicy.vue'
import selectPolicy from '../borrowerTasks/homeInsurance/selectPolicy.vue'
import errorState from '../borrowerTasks/homeInsurance/error.vue'
import verifyInformation from '../borrowerTasks/homeInsurance/verifyInformation.vue'
import scheduleClosingMain from '../borrowerDashboard/ScheduleClosingTask/ScheduleClosingMain.vue'
import scheduleClosingDialog from '../borrowerTasks/scheduleClosing/scheduleClosingDialog.vue'
import nexusPay from '../borrowerTasks/nexusPay/nexusPay.vue'
import DocUpload from './docUpload/apiUploadedDocViewer.vue'

export default {
  components: {
    HI_dashboard: Dashboard,
    HI_verifyInformation: verifyInformation,
    HI_enterPolicy: enterPolicy,
    HI_error: errorState,
    HI_selectPolicy: selectPolicy,
    SC_dialog: scheduleClosingDialog,
    SC_entry: scheduleClosingMain,
    NP_entry: nexusPay,
    LDF_entry: DocUpload
  },
  mixins: [AppCommunicationMixin],
  props: {
    initialComponent: {
      type: String,
      default: '',
      required: false
    }
  },
  data () {
    return {
      animation: { loop: true, currentFrame: 1, totalFrames: 20 },
      loadingDelay: false,
      transitionName: 'fade',
      loadingMessages: [],
      component: ''
    }
  },
  computed: {
    loading () {
      return this.animation.loop || this.animation.currentFrame !== this.animation.totalFrames - 1
    }
  },

  watch: {
    initialComponent (val) {
      this.component = val
    }
  },

  mounted () {
    this.component = this.initialComponent
    this.animation = lottie.loadAnimation({
      container: this.$refs.loadingView,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: loadingJSON
    })
    // Global Hook for going to a specific route via Vue programatic navigation from mobile
    window.navigateTo = (route) => {
      this.$route.params.taskGuid = route.params.taskGuid
      this.navigationEvent(route.name)
    }

    // Global Hook for navigating back one screen from mobile
    window.navigateBack = () => {
      this.hideLoader(true)
      let nextState = ''
      switch (this.component) {
        case 'HI_dashboard':
          nextState = 'close'
          break
        case 'HI_verifyInformation':
          nextState = 'HI_dashboard'
          break
        case 'HI_enterPolicy':
          nextState = 'HI_dashboard'
          break
        case 'HI_error':
          nextState = 'HI_verifyInformation'
          break
        case 'HI_selectPolicy':
          nextState = 'HI_dashboard'
          break
        case 'SC_main':
          nextState = 'close'
          break
        case 'SC_dialog':
          nextState = 'SC_entry'
          break
        default:
          nextState = 'close'
      }
      this.navigationEvent(nextState, 'backward')
    }
  },

  methods: {
    showLoader (messages) {
      this.loadingMessages = messages || []
      this.animation.loop = true
      this.animation.play()
    },
    hideLoader (immediately = false) {
      if (immediately) {
        this.animation.stop()
        this.animation.loop = false
        this.animation.currentFrame = this.animation.totalFrames - 1
      } else {
        this.animation.loop = false
      }
    },
    navigationEvent (name, direction = 'forward') {
      if (this.$store.inMobileApp) {
        this.transitionName = direction === 'forward' ? 'slide-left' : 'slide-right'
      } else {
        this.transitionName = 'fade'
      }
      if (name === 'close') {
        this.postMessageToApp(CLOSE)
        this.$emit('completed')
      } else {
        this.component = name
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.loading-wrapper {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  z-index: 9999;
}
#loading-view {
  height: 150px;
  width: 150px;
}

.router-wrapper {
  transition: opacity 0.5s;
}

.hidden {
  opacity: 0;
}

.fade-enter-to {
  opacity: 1;
  transition: opacity 0.5s;
}
.fade-enter {
  opacity: 0;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-left-leave-active,
.slide-left-enter-active {
  transition: 0.25s;
}
.slide-left-enter {
  transform: translate(100%, 0);
}
.slide-left-leave-to {
  transform: translate(-100%, 0);
}

.slide-right-leave-active,
.slide-right-enter-active {
  transition: 0.25s;
}
.slide-right-enter {
  transform: translate(-100%, 0);
}
.slide-right-leave-to {
  transform: translate(100%, 0);
}
.full-height {
  height: 100%;
  min-height: 400px;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .3s ease;
}
.fade-enter, .fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
