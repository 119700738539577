<template>
  <div class="px-4 px-md-0 pb-4 full-height">
    <v-layout
      row
      wrap
    >
      <v-flex
        xs12
        sm4
        offset-sm4
      >
        <section>
          <div class="snui-btn-icon-wrapper mb-18px">
            <v-icon class="snui-btn-icon">
              {{ $vuetify.icons['sn-icon-thumbs-down'] }}
            </v-icon>
          </div>
          <h4 class="sn-font-weight-extra-light sn-title-1 sn-font-condensed centered-text">
            We're Sorry
          </h4>
          <h6 class="sn-font-weight-regular sn-callout centered-text mb-20px">
            We don't seem to recognize {{ formattedAddress }}
          </h6>
          <SnuiButton
            button-text="Edit Address"
            class="mx-0 mb-8px"
            font-size="15px;"
            height="32px;"
            width="100%;"
            @click="editAddress"
          />
          <SnuiButton
            :condensed-font="false"
            background-color="white"
            button-text="Cancel"
            class="mx-0"
            font-size="15px;"
            height="32px;"
            :outline-size="1"
            text-color="black"
            width="100%;"
            @click="errorOnCancel"
          />
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import SnuiButton from '~/components/borrowerApp/SNUI/SnuiButton.vue'
import { homeInsuranceSuggestedAddressQuery } from './queries.gql'
import _ from 'lodash'

export default {
  components: {
    SnuiButton
  },

  apollo: {
    suggestedAddress: {
      query: homeInsuranceSuggestedAddressQuery,
      update (data) {
        return _.get(data, 'homeInsurance.suggestedAddress', {
          bestGuessStreetNumber: '',
          bestGuessStreetName: '',
          city: '',
          state: '',
          zip: ''
        })
      }
    }
  },

  data () {
    return {
      suggestedAddress: {
        bestGuessStreetNumber: '',
        bestGuessStreetName: '',
        city: '',
        state: '',
        zip: ''
      }
    }
  },

  computed: {
    formattedAddress () {
      return `${this.suggestedAddress.bestGuessStreetNumber} ${this.suggestedAddress.bestGuessStreetName} ${this.suggestedAddress.city}, ${this.suggestedAddress.state} ${this.suggestedAddress.zip}`
    }
  },

  mounted () {
    this.$nextTick(() => {
      this.$emit('hideLoader')
    })
  },

  methods: {
    editAddress () {
      this.$emit('navigation', 'HI_verifyInformation', 'backward')
    },
    errorOnCancel () {
      this.$emit('navigation', 'HI_dashboard', 'backward')
    }
  }
}
</script>

<style lang="scss" scoped>
.full-height {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wrapper {
  width: 75%;
}
.centered-text {
  text-align: center;
}
.snui-btn-icon-wrapper {
  width: 60px;
  height: 60px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid black;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.snui-btn-icon {
  color: black;
}
.mb-8px {
  margin-bottom: 8px;
}
.mb-18px {
  margin-bottom: 18px;
}
.mb-20px {
  margin-bottom: 20px;
}
</style>
