<template>
  <v-dialog
    v-model="sessionExpirationWarningDialog"
    max-width="290"
  >
    <v-card>
      <v-card-text>Do you need more time?</v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          class="primary white--text"
          aria-label="Yes"
          @click.native="sessionExpirationWarningDialog=false;restartTimeout(true)"
        >
          Yes
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import snSessionsService from '../../services/snSessionsService.js'

export default {
  name: 'SessionTimeout',
  data () {
    return {
      defaultKioskSessionExpirationWarning: 1,
      defaultKioskSessionExpires: 2,
      defaultSessionExpirationWarning: 10,
      defaultSessionExpires: 15,
      haveDefaultValues: false,
      sessionExpirationWarningDialog: false,
      sessionExpires: null,
      sessionExpiresWarning: null,
      store: this.$store,
      timeout: null
    }
  },

  created () {
    document.addEventListener('click', this.restartTimeout)

    this.restartTimeout()
  },

  methods: {
    isKioskMode () {
      if (this.store.servicer && this.store.servicer.web_kiosk_enabled === true && navigator.userAgent.indexOf('iPad') !== -1) {
      // FOR TESTING
      // if (this.store.servicer && this.store.servicer.web_kiosk_enabled === true) {
        return true
      } else {
        return false
      }
    },

    kioskRedirectUrl () {
      if (this.store.servicer.kiosk_loan_app_redirect_url !== null && this.store.servicer.kiosk_loan_app_redirect_url.length > 4) {
        return this.store.servicer.kiosk_loan_app_redirect_url
      } else {
        return '/borrower/signup/' + this.store.activationCode
      }
    },

    restartTimeout (ping) {
      clearTimeout(this.timeout)

      const start = new Date().getTime()

      if (this.store.currentUser !== null && (ping === true || this.haveDefaultValues === false)) {
        snSessionsService.ping()
          .then((response) => {
            this.defaultSessionExpires = response.data.sessionExpiresInMinutes
            this.defaultSessionExpirationWarning = response.data.sessionExpiresWarningMinutes
            if (this.defaultSessionExpires !== undefined && this.defaultSessionExpirationWarning !== undefined) {
              this.haveDefaultValues = true
            }
            this.sessionExpiresWarning = start + this.defaultSessionExpirationWarning * 1000 * 60
            this.sessionExpires = start + this.defaultSessionExpires * 1000 * 60
          })
      }

      if (this.isKioskMode()) {
        this.sessionExpiresWarning = start + this.defaultKioskSessionExpirationWarning * 1000 * 60
        this.sessionExpires = start + this.defaultKioskSessionExpires * 1000 * 60
      } else {
        this.sessionExpiresWarning = start + this.defaultSessionExpirationWarning * 1000 * 60
        this.sessionExpires = start + this.defaultSessionExpires * 1000 * 60
      }

      this.timeout = setInterval(() => {
        var now = new Date().getTime()
        if (now > this.sessionExpires) {
          if (this.isKioskMode()) {
            window.location.href = '/logout?url=' + this.kioskRedirectUrl()
          } else if (this.store.currentUser !== null) {
            window.location.href = '/logout'
          }
        } else if (now >= (this.sessionExpiresWarning - 1000) && this.store.currentUser !== null) {
          this.sessionExpirationWarningDialog = true
        }
      }, 60000)
    }
  }
}
</script>
