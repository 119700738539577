<template>
  <v-layout
    row
    wrap
  >
    <div
      v-if="!this.$vuetify.breakpoint.smAndDown"
      style="display: inline-block; margin-right: 16px; margin-left: auto; vertical-align: top; "
    >
      <v-btn
        v-if="!$vuetify.breakpoint.smAndDown"
        flat
        style="margin: 0px; "
        icon
        color="black"
        @click="$emit('navigation', 'HI_dashboard', 'backward')"
      >
        <v-icon>{{ $vuetify.icons['sn-icon-chevron-left-small'] }}</v-icon>
      </v-btn>
    </div>
    <div
      :class="getStyle()"
    >
      <div :class="getMarginClass()">
        <div
          :class="getCardWidth()"
        >
          <template v-if="hasQuotes">
            <h4 class="sn-font-weight-extra-light sn-title-1 sn-font-condensed mb-18px">
              Here is what we found
            </h4>
            <h4 class="sn-callout sn-font-weight-regular mb-4px">
              Buy an instant policy online
            </h4>
            <QuoteCard
              v-for="(quote, index) in filterQuotes(quotes)"
              :key="quote.guid"
              :quote="quote"
              :style="index > 0 ? 'margin-top: 10px;' : ''"
              @showLoader="showLoader"
              @hideLoader="$emit('hideLoader')"
            />
          </template>
          <h4
            v-else
            class="sn-font-weight-extra-light sn-title-1 sn-font-condensed mb-18px"
          >
            We didn't find any online results
          </h4>
          <template v-if="servicerProfile && !!servicerProfile.preferredInsuranceProviders.length">
            <v-flex
              width="100%"
              class="clickable"
              :class="hasQuotes ? 'mt-30px' : ''"
            >
              <h4 class="sn-callout sn-font-weight-regular mb-4px">
                {{ hasQuotes ? `Or,` : `But you can still` }} contact one of {{ servicerProfile.name }}'s preferred providers:
              </h4>
              <v-card
                v-for="(provider, index) in servicerProfile.preferredInsuranceProviders"
                :key="provider.guid"
                style="height: 72px;"
                :style="index > 0 ? 'margin-top: 10px;' : ''"
                @click="openPreferredProvider(provider)"
              >
                <v-card-text
                  class="px-0 py-0 center-align"
                >
                  <v-layout
                    row
                    class="center-align"
                  >
                    <v-flex
                      v-if="$vuetify.breakpoint.smAndDown"
                      d-flex
                      xs7
                    >
                      <v-icon
                        style="max-width: 73px; width: 100%;"
                        color="black"
                      >
                        {{ $vuetify.icons['sn-icon-person'] }}
                      </v-icon>
                      <h4 class="sn-callout sn-font-weight-medium single-line">
                        {{ provider.name }}
                      </h4>
                    </v-flex>
                    <v-flex
                      v-else
                      d-flex
                      xs8
                    >
                      <v-icon
                        style="max-width: 73px; width: 100%;"
                        color="black"
                      >
                        {{ $vuetify.icons['sn-icon-person'] }}
                      </v-icon>
                      <v-tooltip
                        top
                        :open-delay="1000"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <h4
                            v-bind="attrs"
                            class="sn-callout sn-font-weight-medium single-line"
                            v-on="on"
                          >
                            {{ provider.name }}
                          </h4>
                        </template>
                        <span>{{ provider.name }}</span>
                      </v-tooltip>
                    </v-flex>
                    <v-flex
                      d-flex
                      style="align-items: center;"
                    >
                      <span
                        class="sn-caption-1 sn-font-weight-regular"
                        style="text-transform: uppercase; text-align: right;"
                      >
                        ask for quotes
                      </span>
                    </v-flex>
                    <v-flex
                      d-flex
                      xs1
                      style="align-items: center;"
                    >
                      <v-icon
                        color="black"
                        style="font-size: 22px;"
                      >
                        {{ $vuetify.icons['sn-icon-chevron-right-small'] }}
                      </v-icon>
                    </v-flex>
                  </v-layout>
                </v-card-text>
              </v-card>
            </v-flex>

            <v-bottom-sheet v-model="preferredProviderSheet">
              <v-list>
                <v-subheader class="barlow-font">
                  Contact {{ currentProvider.name }}
                </v-subheader>
                <v-list-tile
                  @click="openPreferredProviderWebsite(); preferredProviderSheet = false"
                >
                  <v-list-tile-action>
                    <v-icon
                      color="black"
                      style="font-size: 22px;"
                    >
                      {{ $vuetify.icons['sn-icon-external-link'] }}
                    </v-icon>
                  </v-list-tile-action>
                  <v-list-tile-title class="barlow-font">
                    Visit Website
                  </v-list-tile-title>
                </v-list-tile>
                <v-list-tile
                  @click="callPreferredProvider(); preferredProviderSheet = false"
                >
                  <v-list-tile-action>
                    <v-icon
                      color="black"
                      style="font-size: 22px;"
                    >
                      {{ $vuetify.icons['sn-icon-phone'] }}
                    </v-icon>
                  </v-list-tile-action>
                  <v-list-tile-title class="barlow-font">
                    Call {{ currentProvider.formattedPhoneNumber }}
                  </v-list-tile-title>
                </v-list-tile>
              </v-list>
            </v-bottom-sheet>
          </template>
        </div>
      </div>
    </div>
  </v-layout>
</template>

<script>
import {
  servicerProfileQuery,
  borrowerHomeInsuranceTaskQuotesQuery,
  homeInsuranceSuggestedAddressQuery
} from './queries.gql'
import AppCommunicationMixin, {
  OPEN_CUSTOM_LINK
} from '~/components/common/mixins/AppCommunicationMixin'
import QuoteCard from './QuoteCard.vue'

export default {
  components: {
    QuoteCard
  },
  mixins: [AppCommunicationMixin],
  apollo: {
    quotes: {
      query: borrowerHomeInsuranceTaskQuotesQuery,
      fetchPolicy: 'network-only',
      variables () {
        return {
          guid: this.$route.params.taskGuid
        }
      },
      update (data) {
        if (data?.evidenceOfInsuranceTask?.insuranceQuotes?.length) {
          const insuranceQuotes = this.filterQuotes(data?.evidenceOfInsuranceTask?.insuranceQuotes)
          this.$emit('hideLoader')
          if (insuranceQuotes.length) {
            return data?.evidenceOfInsuranceTask?.insuranceQuotes.sort((a, b) => {
              return (a.premium || 999999) - b.premium
            })
          }
        } else if (!this.hasPreferredProvider && !this.$apollo.queries.servicerProfile.loading) {
          this.$emit('navigation', 'HI_error', 'backward')
        }
        return []
      }
    },

    servicerProfile: {
      query: servicerProfileQuery,
      skip () {
        return this.state === undefined
      },
      variables () {
        return {
          state: this.state
        }
      },
      update (data) {
        if (data?.servicer_profile?.preferredInsuranceProviders) {
          this.$emit('hideLoader')
          return data?.servicer_profile
        } else if (!this.hasQuotes && !this.$apollo.queries.quotes.loading) {
          this.$emit('navigation', 'HI_error', 'backward')
        }
        return undefined
      }
    },

    state: {
      query: homeInsuranceSuggestedAddressQuery,
      manual: true,
      result ({ data }) {
        this.state = data?.homeInsurance?.suggestedAddress?.state
      }
    }
  },
  data () {
    return {
      preferredProviderSheet: false,
      currentProvider: {},
      state: undefined
    }
  },
  computed: {
    hasPreferredProvider () {
      return !!this.servicerProfile?.preferredInsuranceProviders?.length
    },
    hasQuotes () {
      return this.quotes?.length
    }
  },

  methods: {
    showLoader (messages) {
      this.$emit('showLoader', messages)
    },
    getMarginClass () {
      return {
        'ma-3': this.$vuetify.breakpoint.xs,
        'mr-136px ml-0': !this.$vuetify.breakpoint.smAndDown
      }
    },
    getStyle () {
      return {
        'width-100 margin-auto': this.$vuetify.breakpoint.smAndDown
      }
    },
    getCardWidth () {
      return {
        'width-large': !this.$vuetify.breakpoint.smAndDown
      }
    },
    openPreferredProvider (provider) {
      this.currentProvider = provider
      if (provider.formattedPhoneNumber && provider.website) {
        if (this.$store.inMobileApp || this.$vuetify.breakpoint.smAndDown) {
          this.preferredProviderSheet = true
        } else {
          this.openPreferredProviderWebsite()
        }
      } else if (provider.formattedPhoneNumber) {
        this.callPreferredProvider()
      } else {
        this.openPreferredProviderWebsite()
      }
    },

    openPreferredProviderWebsite () {
      if (this.$store.inMobileApp) {
        this.postMessageToApp(OPEN_CUSTOM_LINK, this.currentProvider.website)
      } else {
        window.open(this.currentProvider.website, '_blank')
      }
    },

    callPreferredProvider () {
      window.location = `tel:${this.currentProvider.unformattedPhoneNumber}`
    },

    filterQuotes (quotes) {
      var visibleQuotes = []
      for (var ii = 0; ii < quotes?.length; ii++) {
        var quote = quotes[ii]
        if (quote.insuranceQuoteType === 'Borrower::EvidenceOfInsurance::ProgressiveQuote' &&
           (quote.progressiveQuoteId || quote.extendedQuoteId)) {
          visibleQuotes.push(quote)
        } else {
          visibleQuotes.push(quote)
        }
      }
      return visibleQuotes
    }
  }
}
</script>

<style lang="scss" scoped>
.mb-4px {
  margin-bottom: 4px;
}

.mb-18px {
  margin-bottom: 18px;
}

.mt-30px {
  margin-top: 30px;
}

.py-25px {
  padding-top: 25px;
  padding-bottom: 25px;
}
.mr-136px {
  margin-right: 136px;
}
.width-large {
  width: 432px;
}
.width-100 {
  width: 100%;
  max-width: 432px;
}
.margin-auto {
  margin: auto;
}
.clickable {
  cursor: pointer;
}
.single-line {
  white-space:nowrap;
  overflow:hidden;
  text-overflow: ellipsis;
}
.center-align {
  height: 100%;
  align-items: center;
}
</style>
