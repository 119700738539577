<template>
  <div>
    <v-card
      class="hoverable elevation-1 mb-2"
      @click="headerTap($vuetify.breakpoint.mdAndUp)"
    >
      <v-layout
        row
        class="pl-4 pr-3 vertical-align"
      >
        <div
          v-show="$vuetify.breakpoint.mdAndUp || !expandTask"
          style="padding-top: 2px;"
        >
          <v-icon
            v-if="scheduleClosingTask.completed"
            class="mr-2 outlined"
            color="success"
            size="19"
          >
            {{ $vuetify.icons['sn-icon-check'] }}
          </v-icon>
          <v-icon
            v-else
            class="mr-2 outlined"
            color="grey"
            size="19"
          >
            {{ $vuetify.icons['sn-icon-calendar'] }}
          </v-icon>
        </div>

        <v-flex
          xs11
          class="pt-3 pb-4"
        >
          <div class="title-text barlow-font">
            Schedule Closing Appointment
          </div>
          <div
            v-if="scheduleClosingTask.completed"
            class="subtitle-text barlow-font"
            :style="{ color: successColor }"
          >
            Scheduled for {{ formatSubheadingDate() }}
          </div>
          <div
            v-else
            class="subtitle-text barlow-font"
          >
            Select a time to close on your home
          </div>
        </v-flex>

        <v-flex>
          <v-icon
            v-show="$vuetify.breakpoint.mdAndUp || !expandTask"
            color="grey"
            medium
          >
            {{ expandTask ? $vuetify.icons['sn-icon-chevron-up-small'] : $vuetify.icons['sn-icon-chevron-down-small'] }}
          </v-icon>
        </v-flex>
      </v-layout>
      <v-divider
        v-show="$vuetify.breakpoint.mdAndDown"
        style="margin-right:16px; margin-left: 15px;"
      />
      <v-expand-transition v-show="scheduleClosingTask.proposedClosingTime == null">
        <div>
          <!-- This container holds the data for if the task has had no progress on it (not proposed, confirmed, or completed) -->
          <v-container
            v-show="expandTask && (scheduleClosingTask.proposedClosingTime == null || scheduleClosingTask.proposedClosingTime == '')"
            :class="{'pb-6 pt-0 px-6': $vuetify.breakpoint.mdAndUp, 'pb-6 pt-0': $vuetify.breakpoint.smAndDown}"
            column
          >
            <v-flex
              class="header-text barlow-condensed-font"
              :class="{'pt-3': $vuetify.breakpoint.smAndDown}"
            >
              Congratulations! You're almost ready to close
            </v-flex>

            <v-container
              class="pa-0 mt-4"
              :style="{'display': $vuetify.breakpoint.mdAndUp ? 'flex' : '', 'text-align': ($vuetify.breakpoint.smAndDown ? 'center' : '')}"
            >
              <div
                ref="congratsView"
                :class="{'pa-0 mb-4': $vuetify.breakpoint.smAndDown}"
                :style="{'display': 'inline-block', 'margin-right': ($vuetify.breakpoint.smAndDown ? '0px' : '32px'), 'background-color': 'grey', 'width': ($vuetify.breakpoint.smAndDown ? '180px' : '160px'), 'height': ($vuetify.breakpoint.smAndDown ? '180px' : '160px')}"
              />

              <div :style="{'flex': 1, 'width': ($vuetify.breakpoint.smAndDown ? 'auto' : '440px')}">
                <div
                  class="barlow-font"
                  style="text-align: left;"
                >
                  <p
                    :style="{'padding': 0, 'margin': 0, 'width': ($vuetify.breakpoint.smAndDown ? '344px' : '440px') }"
                    v-html="getBody(formatDate(scheduleClosingTask.closingDate))"
                  />
                </div>
                <v-btn
                  outline
                  :style="{'flex': 1, 'border-radius': '0px', 'background-color': 'black !important', 'height': '52px', 'width': ($vuetify.breakpoint.smAndDown ? '344px' : '440px')}"
                  tile
                  :class="{'schedule-button my-3 mx-0': $vuetify.breakpoint.mdAndUp, 'my-3 mx-0': $vuetify.breakpoint.smAndDown}"
                  @click.stop="scheduleButtonTap"
                >
                  <span
                    class="barlow-condensed-font"
                    style="font-size: 20px; font-weight: 500; color: white; letter-spacing: 1.5px"
                  >SCHEDULE APPOINTMENT TIME</span>
                </v-btn>
              </div>
            </v-container>
            <ScheduleClosingDialog
              v-if="task"
              v-model="scheduleDialog"
              :task="task"
              @completed="dialogCompleted()"
            />
          </v-container>

          <!-- This container is for after the borrower has requested a time, but hasn't confirmed it -->
          <v-container
            v-show="expandTask && (scheduleClosingTask.proposedClosingTime != null && scheduleClosingTask.proposedClosingTime != '') && (scheduleClosingTask.completed == null || !scheduleClosingTask.completed)"
            :class="{'pb-6 pt-0 px-6': $vuetify.breakpoint.mdAndUp}"
            column
          >
            <v-flex class="header-text barlow-condensed-font">
              Does this look right?
            </v-flex>

            <v-container
              class="look-right-container pt-3 pl-3 pb-3 pr-1 mt-3"
              style="padding-top: 19px important!; display: flex; background-color: #F2F2F2"
            >
              <div style="flex: 4;">
                <div
                  style="font-weight: 500; font-size: 13px; line-height: 18px;"
                  class="barlow-font"
                >
                  Your closing appointment will be requested for
                </div>
                <div
                  style="margin-top: 3px; font-weight: 600; font-size: 16px; line-height: 21px;"
                  class="barlow-font"
                >
                  {{ formatProposedClosingTime() }} ({{ getTimeZone() }})
                </div>
              </div>

              <div
                style="display: flex; align-items: center; flex: 1; width: align-content: center; text-align: center; justify-content: center;"
                class="ma-0 pa-0"
                justify-end
              >
                <v-btn
                  v-if="$vuetify.breakpoint.mdAndUp"
                  outline
                  style="border-radius: 0px; background-color: white !important;"
                  tile
                  class="edit-button ma-0"
                  @click.stop="scheduleButtonTap"
                >
                  <span
                    class="barlow-condensed-font"
                    style="font-size: 15px; font-weight: 600;"
                  >
                    Edit
                  </span>
                </v-btn>
                <v-icon
                  v-else
                  size="18"
                  style="background-color: white !important"
                  class="outlined"
                  @click.stop="scheduleButtonTap"
                >
                  {{ $vuetify.icons['sn-icon-pencil'] }}
                </v-icon>
              </div>
            </v-container>

            <v-container class="pa-0 mt-3">
              <span
                style="font-size: 17px; line-height: 22px; font-weight: 400;"
                class="barlow-font"
              >
                You'll need to contact your settlement agent to make changes after your appointment request is sent.
              </span>
            </v-container>

            <v-container
              class="footer-container pa-0 pb-4"
              style="display: flex;"
            >
              <div
                v-show="false"
                style="flex: 1"
                justify-start
                class="mt-4"
              >
                <v-btn
                  style="border: 0px !important; border-radius: 0px"
                  outline
                  tile
                  small
                  class="ask-question-button ma-0"
                >
                  <v-icon class="pr-1">
                    {{ $vuetify.icons['sn-icon-chat'] }}
                  </v-icon>
                  <span
                    style="font-size: 15px; font-weight: 600; line-height: 20px;"
                    class="barlow-font"
                  >
                    ASK A QUESTION
                  </span>
                </v-btn>
              </div>

              <div
                :style="{'flex': 1, 'position': ($vuetify.breakpoint.smAndDown ? 'relative' : 'absolute'), 'right': ($vuetify.breakpoint.smAndDown ? '' : '64px'), 'text-align': ($vuetify.breakpoint.smAndDown ? 'center' : 'auto')}"
                class="mt-4"
              >
                <v-btn
                  v-show="false"
                  style="width: 22px; height: 22px; border: 0px !important; border-radius: 0px"
                  outline
                  tile
                  small
                  class="ask-question-button ma-0 pa-0"
                >
                  <v-icon class="pr-1">
                    {{ $vuetify.icons['sn-icon-menu-dots-vertical'] }}
                  </v-icon>
                </v-btn>

                <v-btn
                  :style="{'width': ($vuetify.breakpoint.smAndDown ? '344px' : '224px'), 'height': ($vuetify.breakpoint.smAndDown ? '50px' : '32px'), 'border': '0px !important', 'border-radius': '0px', 'background-color': 'black !important'}"
                  outline
                  tile
                  small
                  class="ma-0"
                  @click.stop="completeTask"
                >
                  <span
                    style="font-size: 15px; color: white !important; font-weight: 600; line-height: 20px; letter-spacing: 1.5px;"
                    class="barlow-font"
                  >
                    REQUEST APPOINTMENT</span>
                </v-btn>
              </div>
            </v-container>
          </v-container>
          <!-- This container is for after the borrower has confirmed their requested time -->
          <v-container
            v-show="expandTask && scheduleClosingTask.completed"
            :class="{'pb-6 pt-0 px-6': $vuetify.breakpoint.mdAndUp}"
            column
          >
            <v-flex class="header-text barlow-condensed-font">
              Your appointment request is {{ scheduleClosingTask.confirmed ? 'confirmed!' : 'being reviewed.' }}
            </v-flex>

            <v-container
              class="look-right-container pt-3 mt-3"
              style="padding-top: 19px important!; display: flex; background-color:#F2F2F2"
            >
              <div style="flex: 4;">
                <div
                  style="font-weight: 500; font-size: 13px; line-height: 18px;"
                  class="barlow-font"
                >
                  Your closing appointment {{ scheduleClosingTask.confirmed ? 'is scheduled for' : 'has been requested for' }}
                </div>
                <div
                  style="margin-top: 3px; font-weight: 500; font-size: 16px; line-height: 21px;"
                  class="barlow-font"
                >
                  {{ formatProposedClosingTime() }}
                </div>
              </div>
            </v-container>

            <v-container class="pa-0 mt-3">
              <span
                style="font-size: 17px; line-height: 22px; font-weight: 400;"
                class="barlow-font"
              >
                Please contact your settlement agent if you need to make changes.
              </span>
            </v-container>

            <v-container
              class="footer-container pa-0"
              :style="{'display': 'flex', 'text-align': ($vuetify.breakpoint.smAndDown ? 'center' : 'left')}"
            >
              <div
                v-show="false"
                style="flex: 1"
                justify-start
                class="mt-4"
              >
                <v-btn
                  :style="{'border': ($vuetify.breakpoint.smAndDown ? '1px solid !important' : '0px !important'), 'border-radius': '0px'}"
                  outline
                  tile
                  small
                  class="ask-question-button ma-0"
                >
                  <v-icon class="pr-1">
                    {{ $vuetify.icons['sn-icon-chat'] }}
                  </v-icon>
                  <span
                    style="font-size: 15px; font-weight: 600; line-height: 20px;"
                    class="barlow-font"
                  >
                    CHAT WITH SETTLEMENT AGENT
                  </span>
                </v-btn>
              </div>
            </v-container>
          </v-container>
        </div>
      </v-expand-transition>
    </v-card>
  </div>
</template>

<script>
import lottie from 'lottie-web'
import congratsJSON from '~/assets/lottieAnimations/congratulations.json'
import ScheduleClosingDialog from './ScheduleClosingDialog'
import { completeScheduleCloseTask } from '../dashboardChecklist.gql'
import { borrowerScheduleClosingTaskQuery } from '../../borrowerTasks/scheduleClosing/queries.gql'

export default {
  name: 'ScheduleClosing',
  components: { ScheduleClosingDialog },
  props: {
    task: {
      type: Object,
      required: false,
      default: null
    }
  },
  data () {
    return {
      taskConfirmedByBorrower: false,
      scheduleClosingTask: '',
      scheduleDialog: false,
      expandTask: false,
      animation: { loop: true, currentFrame: 1, totalFrames: 20 }
    }
  },
  computed: {
    successColor () {
      return this.$vuetify.theme.success
    }
  },
  watch: {
    scheduleClosingTask: function (val, oldVal) {
      this.updateExpandTask()
    }
  },
  created () {
    window.addEventListener('resize', this.updateExpandTask)
  },
  mounted () {
    this.animation = lottie.loadAnimation({
      container: this.$refs.congratsView,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: congratsJSON
    })
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.updateExpandTask)
  },
  apollo: {
    scheduleClosingTask: {
      query: borrowerScheduleClosingTaskQuery,
      fetchPolicy: 'network-only',
      variables () {
        if (this.task != null) {
          return { guid: this.task.guid }
        } else {
          return { guid: this.$route.params.taskGuid }
        }
      },
      update (data) {
        this.$emit('hideLoader')
        return data.scheduleClosingTask
      }
    }
  },
  methods: {
    updateExpandTask () {
      this.expandTask = this.$store.inMobileApp || this.$vuetify.breakpoint.smAndDown || (this.scheduleClosingTask.proposedClosingTime != null && this.scheduleClosingTask.proposedClosingTime !== '' && !this.scheduleClosingTask.completed)
    },
    formatDate (date) {
      return this.$moment(date).format('MMM Do, YYYY')
    },
    formatProposedClosingTime () {
      const inputFormatString = 'YYYY-MM-DD hh:mm:ss z'
      const day = this.$moment.utc(this.scheduleClosingTask.proposedClosingTime, inputFormatString).local().format('ddd, MMM DD, YYYY')
      const startTime = this.$moment.utc(this.scheduleClosingTask.proposedClosingTime, inputFormatString).local().format('h:mm a')
      const endTime = this.$moment.utc(this.scheduleClosingTask.proposedClosingTime, inputFormatString).add(1, 'hours').local().format('h:mm a')
      return `${day}, ${startTime.toUpperCase()} - ${endTime.toUpperCase()}`
    },
    formatSubheadingDate () {
      return this.$moment.utc(this.scheduleClosingTask.proposedClosingTime, 'YYYY-MM-DD HH:mm:ss ZZ').local().format('DD.MMM.YYYY').toString().toUpperCase()
    },
    completeTask () {
      this.$apollo.mutate({
        mutation: completeScheduleCloseTask,
        variables: {
          guid: this.scheduleClosingTask.guid
        }
      }).then((result) => {
        this.taskConfirmedByBorrower = true
        if (this.$store.inMobileApp) {
          this.$emit('navigation', 'close')
        } else {
          this.$emit('completed')
        }
      })
    },
    getBody (date) {
      if (date === '' || date == null) { date = 'soon' }
      return `You are scheduled to close <b>${date}</b>.`
    },
    getCloseType () {
      if (this.scheduleClosingTask.closeType === 'traditional') { return 'close in person' } else if (this.scheduleClosingTask.closeType === 'online') { return 'eClose online' }
    },
    dialogCompleted () {
      if (this.scheduleClosingTask.proposedClosingTime != null) {
        this.scheduleDialog = false
      }
      this.$emit('completed')
    },
    getTimeZone () {
      var zone = new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2]
      return zone
    },
    headerTap (enabled) {
      if (enabled) {
        this.expandTask = !this.expandTask
      }
    },
    gotoDialog () {
      this.$emit('showLoader')
      this.$emit('navigation', 'SC_dialog')
    },
    scheduleButtonTap () {
      if (this.$store.inMobileApp) {
        this.gotoDialog()
      } else {
        this.scheduleDialog = true
      }
    }
  }
}
</script>

<style>
.title-text {
  font-weight: 600;
  font-size: 17px;
  color: black;
}
.subtitle-text {
  font-size: 15px;
  font-weight: 500;
  color: #A5A5A5;
}
.v-icon.outlined {
  border: 1px solid currentColor;
  border-radius:50%;
  height: 28px;
  width: 28px;
}
.header-text {
  font-size: 32px;
  font-weight: 200;
}
.schedule-button {
  height: 52px;
  width: 440px;
  border-radius: 0px;
  background-color: black !important;
}
.body-container {
  flex: 1;
}
.look-right-container {
  background-color: #f2f2f2;
}
.edit-button {
  height: 32;
  width: 132px;
  letter-spacing: 1.5px;
}
.footer-container {
  margin-top: 35px !important;
  border: 0px;
  border-top: 1px solid grey;
}
.barlow-font {
  font-family: "Barlow", sans-serif !important;
}
.barlow-condensed-font {
  font-family: "Barlow Condensed", sans-serif !important;
}

</style>
