<template>
  <div :class="getClass()">
    <section>
      <div
        v-if="evidenceOfInsuranceTask && evidenceOfInsuranceTask.completed"
      >
        <h4 class="sn-font-weight-semi-bold sn-title-3 mb-2px">
          Proof of Home Insurance
        </h4>
        <h5 class="sn-font-weight-medium sn-subhead sn-text-primary--lighten-2">
          Submitted on {{ formattedDate }}
        </h5>
      </div>
      <h4
        v-else
        class="sn-font-weight-extra-light sn-title-1 sn-font-condensed mb-18px"
      >
        Who is your policy with?
      </h4>
      <div
        v-if="evidenceOfInsuranceTask && !$vuetify.breakpoint.smAndDown"
        class="pa-0"
        style="max-width: 344px;"
      >
        <v-text-field
          ref="companyName"
          v-model="companyName"
          label="Company Name"
          hint="Company"
          class="asterisk"
        />
        <snPhoneInput
          ref="phoneNumber"
          v-model="phoneNumber"
          label="Company Phone Number"
          :asterisk="true"
        />
        <v-text-field
          ref="policyNumber"
          v-model="policyNumber"
          label="Policy Number"
          hint="Policy"
        />
      </div>
      <v-layout
        v-if="$vuetify.breakpoint.smAndDown"
        row
        wrap
      >
        <v-flex
          xs12
        >
          <div
            v-if="evidenceOfInsuranceTask"
            ref="form"
            class="pa-0"
            @submit="completeForm"
          >
            <v-text-field
              ref="companyName"
              v-model="companyName"
              class="asterisk"
              label="Company Name"
              hint="Company"
            />
            <snPhoneInput
              ref="phoneNumber"
              v-model="phoneNumber"
              label="Company Phone Number"
              :asterisk="true"
            />
            <v-text-field
              ref="policyNumber"
              v-model="policyNumber"
              label="Policy Number"
              hint="Policy"
            />
          </div>
          <sn-btn
            v-if="evidenceOfInsuranceTask && !evidenceOfInsuranceTask.completed"
            class="ma-0 sn-font-weight-semi-bold"
            type="primary"
            style="margin-top: 24px; width: 100%; height: 52px;"
            :disabled="!isValidForm()"
            @click="completeForm"
          >
            Submit
          </sn-btn>
        </v-flex>
      </v-layout>
      <v-layout
        v-else
        style="position: absolute; bottom: 24px; right: 24px; "
      >
        <v-flex>
          <sn-btn
            v-if="evidenceOfInsuranceTask && !evidenceOfInsuranceTask.completed"
            type="secondary"
            class="mb-0 sn-font-weight-semi-bold"
            @click="$emit('navigation', 'HI_dashboard', 'backward')"
          >
            Back
          </sn-btn>
          <sn-btn
            v-else
            type="secondary"
            class="mb-0 sn-font-weight-semi-bold"
            @click="$emit('navigation', 'close', 'backward')"
          >
            Close
          </sn-btn>
        </v-flex>
        <v-flex
          v-if="evidenceOfInsuranceTask && !evidenceOfInsuranceTask.completed"
        >
          <sn-btn
            type="primary"
            class="mb-0 mr-0 sn-font-weight-semi-bold"
            :disabled="!isValidForm()"
            @click="completeForm"
          >
            Submit
          </sn-btn>
        </v-flex>
      </v-layout>
    </section>
  </div>
</template>

<script>
import snPhoneInput from '~/components/common/snPhoneInput.vue'
import { borrowerHomeInsuranceTaskQuery } from './queries.gql'
import { updateFormDataMutation } from './mutations.gql'

export default {
  components: {
    snPhoneInput
  },
  data () {
    return {
      companyName: '',
      phoneNumber: '',
      policyNumber: ''
    }
  },
  apollo: {
    evidenceOfInsuranceTask: {
      query: borrowerHomeInsuranceTaskQuery,
      fetchPolicy: 'network-only',
      variables () {
        return {
          guid: this.$route.params.taskGuid
        }
      },
      update (data) {
        this.$emit('hideLoader')
        return data.evidenceOfInsuranceTask
      }
    }
  },
  computed: {
    formattedDate () {
      if (this.evidenceOfInsuranceTask && this.evidenceOfInsuranceTask.completedAt) {
        const date = this.$moment(this.formatISO(this.evidenceOfInsuranceTask.completedAt))
        return date.format('DD.MMM.YYYY')
      } else {
        return ''
      }
    },
    formValues () {
      if (this.evidenceOfInsuranceTask?.formData?.formValues) {
        return JSON.parse(this.evidenceOfInsuranceTask.formData.formValues)
      } else {
        return ''
      }
    }
  },

  methods: {
    isValidForm () {
      return (this.phoneNumber.length === 14 && !this.$refs.phoneNumber.computedErrors.length && !!this.companyName)
    },
    getClass () {
      return {
        'ma-3': this.$vuetify.breakpoint.smAndDown
      }
    },
    formatISO (rawDate) {
      // already formatted
      if (rawDate.split(' ').length !== 3) return rawDate

      const [calendarDate, time, offset] = rawDate.split(' ')

      return `${calendarDate}T${time}${offset}`
    },
    completeForm () {
      if (this.isValidForm()) {
        this.$apollo.mutate({
          mutation: updateFormDataMutation,
          variables: {
            guid: this.evidenceOfInsuranceTask.formData.guid,
            formValues: JSON.stringify({
              eoi_insurance_phone_number: this.phoneNumber,
              eoi_insurance_provider: this.companyName,
              eoi_policy_number: this.policyNumber,
              eoi_api_policy_found: false,
              eoi_api_policy_verified: true
            })
          }
        }).then((result) => {
          this.$emit('navigation', 'close')
          if (this.$store.inMobileApp) {
            location.reload()
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.mb-20px {
  margin-bottom: 20px;
}
.mb-18px {
  margin-bottom: 18px;
}
.mb-2px {
  margin-bottom: 2px;
}
.asterisk:after {
  color: red;
  content: " *";
}
</style>
