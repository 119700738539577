/* global mockup:true */
/* eslint no-console: 0, no-unused-vars: 0, no-undef: 0, no-unused-expressions: 0 */
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'url-search-params-polyfill'
import 'intersection-observer'
import Vue from 'vue'
import Router from 'vue-router'
import Vuetify from 'vuetify'
import Sentry from '~/util/sentry'
import VueMoment from 'vue-moment'
import VueAnalytics from 'vue-analytics'
import axios from '~/util/axios'
import apolloClient from '~/util/apolloClient'
import datadog from '~/util/datadog.js'
import 'vuetify/dist/vuetify.min.css'
import '~/assets/css/snFontWeight.css'
import '~/assets/css/snSpacers.css'
import 'mdi/css/materialdesignicons.min.css'
import 'pickerjs/dist/picker.min.css'
import { store } from '../store/borrowerStore.js'
import snackbard from 'snackbard'
import icons from '~/util/icons'
import snAnalyticsService from '../services/snAnalyticsService.js'

import { defaults, resolvers } from '~/components/borrowerApp/borrowerTasks/homeInsurance/resolvers'
import BorrowerApp from '../components/borrowerApp/borrowerApp.vue'
import BorrowerTask from '../components/borrowerApp/borrowerTasks/borrowerTasks.vue'
import HomeInsurance from '../components/borrowerApp/borrowerTasks/homeInsurance/dashboard.vue'
import HomeInsuranceVerifyInformation from '../components/borrowerApp/borrowerTasks/homeInsurance/verifyInformation.vue'
import HomeInsuranceEnterPolicy from '../components/borrowerApp/borrowerTasks/homeInsurance/enterPolicy.vue'
import HomeInsuranceSelectPolicy from '../components/borrowerApp/borrowerTasks/homeInsurance/selectPolicy.vue'
import HomeInsuranceError from '../components/borrowerApp/borrowerTasks/homeInsurance/error.vue'
import nexusPay from '../components/borrowerApp/borrowerTasks/nexusPay/nexusPay.vue'
import ApiUploadedDocViewer from '../components/borrowerApp/borrowerTasks/docUpload/apiUploadedDocViewer.vue'
import { VueMaskDirective } from 'v-mask'

import {
  snBtn,
  snGradientHeader,
  snHeadlineCard
} from '@simplenexus/snui'
import '@simplenexus/snui/dist/assets/index.css' // Styles from SNUI repo

Vue.component('snBtn', snBtn)
Vue.component('snGradientHeader', snGradientHeader)
Vue.component('sn-headline-card', snHeadlineCard)

import('~/assets/css/snIcons.css')

if (['development', 'staging'].includes(process.env.RAILS_ENV)) {
  Vue.config.devtools = true
}

const { VueApollo, provider } = apolloClient([resolvers], [defaults])
datadog({ packName: 'borrowerApp' })

Vue.use(Router)
Vue.use(Vuetify, {
  options: {
    customProperties: true
  },
  icons
})
Vue.use(VueMoment)
Vue.use(snackbard)
Vue.use(VueApollo)

Vue.directive('mask', VueMaskDirective)

Vue.prototype.$sentry = Sentry([{ key: 'pack', value: 'Borrower' }])

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/borrower/contact_originator',
      name: 'contact_your_originator',
      component: () =>
        import('../components/borrowerApp/borrowerContactOriginator.vue')
    },
    {
      path: '/borrower/onboarding/:code?',
      name: 'onboarding',
      component: () =>
        import('../components/borrowerApp/borrowerOnboarding.vue')
    },
    {
      path: '/borrower/dashboard',
      name: 'dashboard',
      component: () =>
        import('../components/borrowerApp/borrowerDashboard.vue')
    },
    {
      path: '/borrower/dashboard/loan/:loanGuid?',
      name: 'loanDashboard',
      component: () =>
        import('../components/borrowerApp/borrowerDashboard.vue')
    },
    {
      path: '/borrower/dashboard/loan_app/:loanAppGuid?',
      name: 'loanAppDashboard',
      component: () =>
        import('../components/borrowerApp/borrowerDashboard.vue')
    },
    {
      path: '/borrower/loans',
      name: 'loans',
      component: () =>
        import('../components/borrowerApp/borrowerMultiLoanSelect/BorrowerMultiLoanSelect.vue')
    },
    {
      path: '/borrower/go_mobile/:code',
      name: 'goMobile',
      component: () => import('../components/borrowerApp/borrowerGoMobile.vue')
    },
    {
      path: '/borrower/custom_form/:code?',
      name: 'customForm',
      component: () =>
        import('../components/borrowerApp/customForm.vue')
    },
    {
      path: '/borrower/custom_form_submitted',
      name: 'customFormSubmitted',
      component: () =>
        import('../components/borrowerApp/customFormSubmitted.vue')
    },
    {
      path: '/borrower/lo_not_found',
      name: 'BorrowerNoLo',
      component: () =>
        import('../components/borrowerApp/borrowerNoLo.vue')
    },
    {
      path: '/:locale?/borrower/signup/:code?',
      name: 'initial',
      component: () => import('../components/borrowerApp/borrowerInitial.vue')
    },
    {
      path: '/borrower/task/',
      name: 'BorrowerTask',
      component: BorrowerTask,
      children: [
        {
          name: 'HI_dashboard',
          path: 'home_insurance/:taskGuid',
          component: HomeInsurance
        },
        {
          name: 'HI_verifyInformation',
          path: 'home_insurance/:taskGuid/verify_information',
          component: HomeInsuranceVerifyInformation
        },
        {
          name: 'HI_selectPolicy',
          path: 'home_insurance/:taskGuid/select_policy',
          component: HomeInsuranceSelectPolicy
        },
        {
          name: 'HI_enterPolicy',
          path: 'home_insurance/:taskGuid/enter_policy',
          component: HomeInsuranceEnterPolicy
        },
        {
          name: 'HI_error',
          path: 'home_insurance/:taskGuid/error',
          component: HomeInsuranceError
        },
        {
          name: 'SC_entry',
          path: 'schedule_closing/:taskGuid/entry',
          component: () => import('../components/borrowerApp/borrowerDashboard/ScheduleClosingTask/ScheduleClosingMain.vue')
        },
        {
          name: 'NP_entry',
          path: 'nexus_pay/:taskGuid/entry',
          component: nexusPay
        },
        {
          name: 'LDF_entry',
          path: 'loan_docs/:taskGuid/entry',
          component: ApiUploadedDocViewer
        }
      ]
    },
    {
      path: '/borrower/dashboard/calculator/:loanOrLoanApp/:guid',
      name: 'BorrowerCalculator',
      component: () => import('../components/borrowerApp/CalculatorIframeWrapper.vue')
    },
    {
      path: '/borrower/dashboard/calculator',
      name: 'BorrowerCalculator',
      component: () => import('../components/borrowerApp/CalculatorIframeWrapper.vue')
    },
    {
      path: '/borrower/mobile_app_redirect',
      name: 'BorrowerMobileAppRedirect',
      component: () => import('../components/borrowerApp/MobileAppRedirect.vue')
    }
  ]
})

router.beforeEach(function (to, from, next) {
  if (to.path.includes('/borrower/dashboard/loan')) {
    location.href = '/homehub'
  }
  window.scrollTo(0, 0)
  next()
})

snAnalyticsService.getIds(store.activationCode)
  .then(({ data }) => {
    const enabled = data.analyticsIds && data.analyticsIds.count !== 0

    Vue.use(VueAnalytics, {
      id: data.analyticsIds,
      disabled: !enabled,
      checkDuplicatedScript: true,
      debug: {
        enabled: false,
        trace: true,
        sendHitTask: true
      },
      router
    })
  })

Vue.prototype.$axios = axios

Vue.filter('base1003Url', store => {
  return '/homehub'
})

Vue.filter('onboardingRoute', (route, sac) => {
  let url = '/borrower/onboarding/' + sac
  if (route?.query?.from_mobile_share === 'true') {
    url += '?from_mobile_share=true'
  }
  return url
})

const app = new Vue({
  el: '#app-container',
  apolloProvider: provider,
  router: router,
  store: store,
  render: createEle => createEle(BorrowerApp)
})
