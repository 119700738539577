var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"fullscreen":"","background-color":"white","overlay-opacity":"0.97","transition":"dialog-bottom-transition"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"ma-0 pa-0 container"},[_c('v-layout',{staticClass:"top-bar",attrs:{"row":"","hide-overlay":"","justify-end":"","fluid":""}},[_c('v-flex',{attrs:{"shrink":""}},[_c('v-btn',{staticClass:"ma-0",attrs:{"justify-end":"","icon":""},on:{"click":function($event){_vm.dialog=false}}},[_c('v-icon',{attrs:{"justify-end":""}},[_vm._v("\n            "+_vm._s(_vm.$vuetify.icons['sn-icon-exit'])+"\n          ")])],1)],1)],1),_vm._v(" "),_c('v-container',{class:{'pa-0': _vm.$vuetify.breakpoint.mdAndUp, 'px-2': _vm.$vuetify.breakpoint.smAndDown},style:({'margin-top': _vm.$vuetify.breakpoint.smAndDown ? '24px' : '205px', width: _vm.$vuetify.breakpoint.smAndDown ? 'auto' : '760px'}),attrs:{"column":""}},[_c('v-layout',{class:{'mt-4': _vm.$vuetify.breakpoint.smAndDown},attrs:{"column":""}},[_c('div',{staticClass:"question-text barlow-condensed-font mb-2"},[_vm._v("\n          What time would you like to close?\n        ")]),_vm._v(" "),_c('div',{staticClass:"question-subtext barlow-font my-3"},[_vm._v("\n          Your closing date is "),_c('b',[_vm._v(_vm._s(_vm.task.closingDate ? _vm.formatClosingDate(_vm.task.closingDate) : 'N/A'))])]),_vm._v(" "),_c('div',{staticClass:"question-subtext barlow-font mb-4"},[_vm._v("\n          Appointment times are listed in "),_c('b',[_vm._v(_vm._s(_vm.getTimeZoneLong()))])]),_vm._v(" "),[_c('div',_vm._l((_vm.slots),function(slot,i){return _c('v-btn',{key:i,class:{'answer-button': _vm.$vuetify.breakpoint.mdAndUp, 'answer-button-small': _vm.$vuetify.breakpoint.smAndDown},style:({
                marginRight: _vm.$vuetify.breakpoint.mdAndUp ? '8px' : '0px',
                marginLeft: '0px',
                borderRadius: '0px',
                marginTop: '0px',
                marginBottom: '8px',
                backgroundColor: slot.selected ? (_vm.primaryThemeColor + " !important") : '',
                color: slot.selected ? (_vm.primaryThemeColor + " !important") : 'black',
              }),attrs:{"outline":"","tile":""},on:{"click":function($event){return _vm.selectSlot(i)}}},[_c('span',{staticClass:"barlow-condensed-font",style:({
                  fontSize: '20px',
                  fontWeight: '500',
                  color: slot.selected ? 'white' : 'black'
                })},[_vm._v(_vm._s(slot.text))])])}),1)]],2),_vm._v(" "),(_vm.selectedTime != null)?_c('v-layout',{class:{'justify-end': _vm.$vuetify.breakpoint.mdAndUp, 'justify-center': _vm.$vuetify.breakpoint.smAndDown}},[_c('v-btn',{class:{ 'submit-button ma-0 mt-4 mr-2': _vm.$vuetify.breakpoint.mdAndUp, 'submit-button-small mt-4': _vm.$vuetify.breakpoint.smAndDown },staticStyle:{"border-radius":"0px","background-color":"black !important"},attrs:{"outline":"","tile":""},on:{"click":_vm.proposeTimeForTask}},[_c('span',{staticClass:"barlow-condensed-font",style:({'color': 'white', 'font-size': _vm.$vuetify.breakpoint.smAndDown ? '20px' : '15px', 'font-weight': 600, 'line-height': '20px'})},[_vm._v("\n            SUBMIT\n          ")])])],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }