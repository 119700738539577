<template>
  <div style="max-width: 375px; margin: auto;">
    <v-container
      :class="{'pa-0': $vuetify.breakpoint.mdAndUp, 'mx-3 pa-0': $vuetify.breakpoint.smAndDown}"
      column
      justify-center
    >
      <v-layout
        column
        style="max-width: 343px;"
        :class="{'mt-3': $vuetify.breakpoint.smAndDown}"
      >
        <div class="question-text barlow-condensed-font mb-2">
          What time would you like to close?
        </div>
        <div class="question-subtext barlow-font my-3">
          Your closing date is <b>{{ scheduleClosingTask.closingDate ? formatClosingDate(scheduleClosingTask.closingDate) : 'N/A' }}</b>
        </div>

        <div class="question-subtext barlow-font mb-4">
          Appointment times are listed in <b>{{ getTimeZoneLong() }}</b>
        </div>

        <template :class="{'column': $vuetify.breakpoint.smAndDown, 'row': $vuetify.breakpoint.mdAndUp}">
          <div style="text-align: center; display: flex; flex-direction: column;">
            <v-btn
              v-for="(slot, i) in slots"
              :key="i"
              :style="{
                marginRight: $vuetify.breakpoint.mdAndUp ? '8px' : '0px',
                marginLeft: '0px',
                borderRadius: '0px',
                backgroundColor: slot.selected ? `${primaryThemeColor} !important` : '',
                color: slot.selected ? `${primaryThemeColor} !important` : 'black',
              }"
              outline
              tile
              :class="{'answer-button': $vuetify.breakpoint.mdAndUp, 'answer-button-small': $vuetify.breakpoint.smAndDown}"
              @click="selectSlot(i)"
            >
              <span
                class="barlow-condensed-font"
                :style="{
                  fontSize: '20px',
                  fontWeight: '500',
                  color: slot.selected ? 'white' : 'black'
                }"
              >{{ slot.text }}</span>
            </v-btn>
          </div>
        </template>
      </v-layout>

      <v-layout
        v-if="selectedTime != null"
        class="mt-4 pb-5"
        style="display: flex"
      >
        <v-btn
          :class="{ 'submit-button ma-0 mt-4 mr-2': $vuetify.breakpoint.mdAndUp, 'submit-button-small ma-0 py-2': $vuetify.breakpoint.smAndDown }"
          style="flex: 1; border-radius: 0px; background-color: black !important;"
          outline
          tile
          @click="proposeTimeForTask"
        >
          <span
            class="barlow-condensed-font"
            :style="{'color': 'white', 'font-size': $vuetify.breakpoint.smAndDown ? '20px' : '15px', 'font-weight': 600, 'line-height': '20px'}"
          >
            SUBMIT
          </span>
        </v-btn>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { proposeTimeScheduleCloseTask } from './mutations.gql'
import { borrowerScheduleClosingTaskQuery } from './queries.gql'

export default {
  name: 'ScheduleClosingDialog',
  components: { },
  props: {
  },
  data () {
    return {
      startTime: 9,
      endTime: 17,
      step: 'hour',
      slots: [],
      selectedTime: null,
      scheduleClosingTask: ''
    }
  },
  computed: {
    primaryThemeColor () {
      return this.$vuetify.theme.primary
    }
  },
  apollo: {
    scheduleClosingTask: {
      query: borrowerScheduleClosingTaskQuery,
      fetchPolicy: 'network-only',
      variables () {
        return {
          guid: this.$route.params.taskGuid
        }
      },
      update (data) {
        this.$emit('hideLoader')
        return data.scheduleClosingTask
      }
    }
  },
  mounted () {
    this.$apollo.queries.scheduleClosingTask.refetch().then((results) => {
      this.slots = this.timeSlots()
    })
  },
  methods: {
    formatClosingDate (date) {
      return this.$moment(date).format('dddd, MMM Do, YYYY')
    },
    proposeTimeForTask () {
      this.$apollo.mutate({
        mutation: proposeTimeScheduleCloseTask,
        variables: {
          guid: this.scheduleClosingTask.guid,
          proposedClosingTime: this.formatProposedTime().toISOString()
        }
      }).then((result) => {
        this.$emit('navigation', 'SC_entry')
      })
    },
    formatProposedTime () {
      // Get time of proposed date and remove meridien
      const time = this.getTimeNoMeridien(this.selectedTime.time)

      // Create final date with closing_date in task
      const datetime = `${this.scheduleClosingTask.closingDate}T${time}`
      return this.$moment(datetime)
    },
    getTimeZoneLong () {
      var zone = new Date().toLocaleTimeString('en-us', { timeZoneName: 'long' }).split(' ').slice(2).join(' ')
      return zone
    },
    getTimeNoMeridien (val) {
      // First check for minutes
      let minutes = '00'
      if (val % 1 !== 0) {
        minutes = (60 * (val % 1)).toString()
      }
      // Then get hour place
      const hours = val < 10 ? `0${Math.floor(val).toString()}` : Math.floor(val).toString()

      return `${hours}:${minutes}`
    },
    getTimeFromInt (val) {
      // First check for minutes
      let minutes = '00'
      if (val % 1 !== 0) {
        minutes = (60 * (val % 1)).toString()
      }
      // Then get hour place
      let hours = Math.floor(val)
      const meridiem = val < 12 ? 'AM' : 'PM'
      if (hours > 12) hours -= 12
      hours = hours.toString()

      return `${hours}:${minutes} ${meridiem}`
    },
    selectSlot (index) {
      for (let i = 0; i < this.slots.length; ++i) {
        if (index === i && this.slots[i].selected) {
          this.slots[i].selected = false
          this.selectedTime = null
        } else if (index === i) {
          this.slots[i].selected = true
          this.selectedTime = this.slots[i]
        } else {
          this.slots[i].selected = false
        }
      }
    },
    timeSlots () {
      // Time slots is computed given the start time, end time, and step length. For example, a start
      // time of 9 and end time of 17 with a step of 'hour' would be every hour between 9am and 5pm.
      let incrementBy
      switch (this.step) {
        case ('hour'):
          incrementBy = 1
          break
        // Add additional step sizes as support is needed
        // case('half-hour'):
        //   incrementBy = 0.5
        //   break
        default:
          incrementBy = 1
          break
      }
      const slots = []
      const startTimes = []
      const arr = JSON.parse(this.scheduleClosingTask.settlementAgentAvailability)
      for (let i = 0; i < arr.length; i++) {
        const deformattedTime = this.$moment(arr[i])
        const dTime = parseInt(deformattedTime.format('HH'))
        startTimes.push(dTime)
      }
      for (const startTime of startTimes) {
        slots.push({
          text: `${this.getTimeFromInt(startTime)} - ${this.getTimeFromInt(startTime + incrementBy)}`,
          selected: false,
          time: startTime
        })
      }
      return slots
    }
  }
}
</script>

<style>
.container {
  background-color: white;
  opacity: 0.97;
  border-radius: 0px !important;
}
.top-bar {
  padding: 25px;
}
.question-text {
  font-size: 28px;
  font-weight: 200;
  line-height: 34px;
}
.question-subtext {
  font-size: 17px;
  font-weight: 400;
  line-height: 22px;
}
.answer-button:hover {
  background-color: var(--v-primary-lighten2) !important;
  color: var(--v-primary-lighten2)
}
.answer-button:hover span {
  color: white !important;
}
.answer-button {
  width: 182px;
  height: 52px;
}
.answer-button-small {
  flex: 1;
  max-width: 344px;
  height: 32px;
}
.answer-button-small:hover {
  background-color: var(--v-primary-lighten2) !important;
  color: var(--v-primary-lighten2)
}
.answer-button-small:hover span {
  color: white !important;
}
.submit-button {
  width: 150px;
  height: 32px;
  background-color: black !important;
}
.submit-button-small {
  max-width: 344px;
  height: 52px;
  background-color: black !important;
}
.barlow-font {
  font-family: "Barlow", sans-serif !important;
}
.barlow-condensed-font {
  font-family: "Barlow Condensed", sans-serif !important;
}

</style>
