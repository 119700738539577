<template>
  <v-btn
    :class="condensedFont ? 'barlow-condensed-font' : 'barlow-font'"
    :disabled="disabled"
    flat
    :style="styles"
    @click="$emit('click')"
  >
    {{ buttonText }}
  </v-btn>
</template>

<script>
export default {
  props: {
    condensedFont: {
      type: Boolean,
      required: false,
      default: false
    },
    outline: {
      type: Boolean,
      required: false,
      default: false
    },
    outlineSize: {
      type: Number,
      required: false,
      default: null
    },
    bold: {
      type: Boolean,
      required: false,
      default: true
    },
    semiBold: {
      type: Boolean,
      required: false,
      default: false
    },
    width: {
      type: String,
      required: false,
      default: '150px'
    },
    height: {
      type: String,
      required: false,
      default: '35px'
    },
    backgroundColor: {
      type: String,
      required: false,
      default: 'black'
    },
    textColor: {
      type: String,
      required: false,
      default: 'white'
    },
    buttonText: {
      type: String,
      required: true
    },
    fontSize: {
      type: String,
      required: false,
      default: '16px'
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    styles () {
      let styles = `background-color: ${this.backgroundColor};color: ${this.textColor};width: ${this.width};height: ${this.height}; font-size: ${this.fontSize};`
      if (this.semiBold) {
        styles = styles + 'font-weight: 600;'
      } else if (this.bold) {
        styles = styles + 'font-weight: bold;'
      }
      if (this.outlineSize) {
        styles = styles + `outline: ${this.outlineSize}px solid ${this.textColor};`
      } else if (this.outline) {
        styles = styles + `outline: 2px solid ${this.textColor};`
      }
      return styles
    }
  }
}
</script>

<style scoped>
.v-btn {
  border-radius: 0px !important;
}
</style>
