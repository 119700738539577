<template>
  <v-card
    width="100%"
    :class="getClass(quote)"
  >
    <div
      :style="{backgroundColor: accentColor}"
      class="side-color-strip"
    />
    <v-card-text
      class="pr-0"
    >
      <v-layout row>
        <v-flex
          d-flex
          xs6
          style="align-items: center;"
          class="clickable"
          @click="cardClicked"
        >
          <v-img
            style="max-width: 154px; min-height: 32px; max-height: 50px;"
            :src="logo"
          />
        </v-flex>
        <v-flex
          d-flex
          xs6
          style="text-align: right;"
        >
          <v-layout
            row
            wrap
          >
            <v-flex
              v-if="quote.premium"
              d-flex
              xs10
              style="padding-top: 4px; padding-bottom: 20px;"
              class="clickable"
              @click="primaryAction"
            >
              <div>
                <span
                  class="sn-title-3 sn-font-weight-medium"
                  style="margin-right: -5px;"
                >
                  ${{ quote.premium }}
                </span>
                <span class="sn-caption-1 sn-font-weight-regular">
                  /YR
                </span>
              </div>
            </v-flex>
            <v-flex
              v-if="quote.premium"
              d-flex
              xs2
              style="padding-top: 4px; padding-bottom: 20px; align-items: center;"
              class="clickable"
              @click="primaryAction"
            >
              <v-icon
                color="black"
                style="font-size: 22px;"
              >
                {{ $vuetify.icons['sn-icon-chevron-right-small'] }}
              </v-icon>
            </v-flex>
            <v-flex
              v-if="doubleAction"
              d-flex
              xs12
              style="padding-bottom: 10px;"
            >
              <v-divider />
            </v-flex>
            <template v-if="quote.__typename === 'ProgressiveQuote'">
              <v-flex
                d-flex
                xs10
                style="padding-top: 7px; padding-bottom: 4px; align-items: center;"
                class="clickable"
                @click="secondaryAction"
              >
                <div>
                  <span
                    class="sn-caption-1 sn-font-weight-regular"
                    style="text-transform: uppercase; align-items: center;"
                  >
                    shop our network
                  </span>
                </div>
              </v-flex>
              <v-flex
                d-flex
                xs2
                style="padding-top: 10px; padding-bottom: 4px; align-items: center;"
                class="clickable"
                @click="secondaryAction"
              >
                <v-icon
                  color="black"
                  style="font-size: 22px;"
                >
                  {{ $vuetify.icons['sn-icon-chevron-right-small'] }}
                </v-icon>
              </v-flex>
            </template>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import progressiveLogo from '~/assets/images/ProgressiveHomeLogo.svg'
import hippoLogo from '~/assets/images/HippoLogo.png'
import {
  progressiveQuoteUrlQuery,
  progressiveExtendedUrlQuery
} from './queries.gql'
import AppCommunicationMixin, {
  OPEN_CUSTOM_LINK
} from '~/components/common/mixins/AppCommunicationMixin'

export default {
  name: 'QuoteCard',
  mixins: [AppCommunicationMixin],
  props: {
    quote: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      progressiveLogo,
      hippoLogo,
      doubleAction: this.quote.__typename === 'ProgressiveQuote' && this.quote.premium
    }
  },
  computed: {
    accentColor () {
      return ((quote) => {
        switch (quote.__typename) {
          case 'ProgressiveQuote':
            return '#1673C3'
          case 'HippoQuote':
            return '#02DE82'
        }
      })(this.quote)
    },
    logo () {
      return ((quote) => {
        switch (quote.__typename) {
          case 'ProgressiveQuote':
            return progressiveLogo
          case 'HippoQuote':
            return hippoLogo
        }
      })(this.quote)
    }
  },

  methods: {
    cardClicked () {
      if (this.quote.premium) {
        this.primaryAction()
      } else {
        this.secondaryAction()
      }
    },
    primaryAction () {
      if (this.quote.__typename === 'HippoQuote') {
        this.hippoPrimaryAction()
      } else {
        this.restoreProgressiveQuote(false)
      }
    },
    secondaryAction () {
      this.restoreProgressiveQuote(true)
    },
    getClass () {
      return {
        'multi-action-card': this.doubleAction,
        'single-action-card': !this.doubleAction
      }
    },
    hippoPrimaryAction () {
      this.openUrl(this.quote.quoteUrl)
    },
    restoreProgressiveQuote (extended = false) {
      this.$emit('showLoader', [{ text: 'Hang tight, we\'re sending you to Progressive\'s website to finalize your quote', classes: 'sn-callout sn-font-weight-regular' }])
      this.$apollo.query({
        fetchPolicy: 'network-only',
        query: extended ? progressiveExtendedUrlQuery : progressiveQuoteUrlQuery,
        variables: {
          guid: this.$route.params.taskGuid,
          platform: this.$vuetify.breakpoint.smAndDown ? 'mobile' : 'desktop'
        }
      })
        .then(({ data }) => {
          this.$emit('hideLoader')

          this.openUrl(data?.evidenceOfInsuranceTask?.insuranceQuotes.find(quote => {
            return quote.__typename === 'ProgressiveQuote'
          })?.url)
        })
        .catch(err => {
          console.log(err)
          this.$emit('hideLoader')
          this.$emit('navigation', 'HI_error', 'backward')
        })
    },
    openUrl (url) {
      if (this.$store.inMobileApp) {
        this.postMessageToApp(OPEN_CUSTOM_LINK, url)
      } else {
        window.open(url, '_blank')
      }
    }
  }
}
</script>

<style scoped>
.side-color-strip {
  width: 3px;
  position: absolute;
  height: 100%;
  border-top-left-radius: 25px !important;
  border-bottom-left-radius: 25px !important;
}
.clickable {
  cursor: pointer;
}
.multi-action-card {
  min-height: 112px;
}
.single-action-card {
  height: 72px;
}
</style>
